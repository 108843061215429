import {getRequest} from "./getRequest";
import { loginRequest, postImageRequest, postRequest } from "./postRequest";

let serverURL = "https://api.8bitdevs.com";
// let serverURL = "http://192.168.0.53:3000";
// let serverURL = "http://localhost:4000"

let api = serverURL + "/admin"

let userRoute = api + "/users/";
let videoRoute = api + "/videos/";
let episodeRoute = api + "/episodes/";
let categoryRoute = serverURL + "/admin/category/";
let newsRoute = serverURL + "/admin/news/";
let settingRoute = serverURL + "/admin/setting/";
let adminUpload = serverURL + "/admin/upload/";

let route = {
    getAllUsers: userRoute + "get/all",
    getOneUser: userRoute + "get/one",
    getAllVideos: videoRoute + "get/all",
    getOneVideo: videoRoute + "get/one",
    postVideo: videoRoute + "create_or_update",
    publicVideo: videoRoute + "public",
    postEpisode: episodeRoute + "create_or_update",
    getAllCategory: categoryRoute + "get/all",
    getOneCategory: categoryRoute + "get/one",
    postOneCategory: categoryRoute + "create_or_update",
    getAllNews: newsRoute + "get/all",
    getOneNews: newsRoute + "get/one",
    postOneNews: newsRoute + "create_or_update",
    getTerm: settingRoute + "term_condition/get",
    getAbout: settingRoute + "about_us/get",
    updateSetting: settingRoute + "update",
    login: api + "/login",
    
}

class API {

    // getAll

    async getAllUsers(query) {
        console.log(route.getAllUsers, query)
        return await getRequest(route.getAllUsers, query);
    }

    async getAllVideos(query) {
        return await getRequest(route.getAllVideos, query);
    }

    async getAllCategory(query) {
        console.log(route.getAllCategory, query)
        return await getRequest(route.getAllCategory, query)
    }

    async getAllNews(query) {
        console.log(route.getAllNews, query)
        return await getRequest(route.getAllNews, query)
    }

    // getOne

    async getOneUser(query) {
        console.log(route.getOneUser, query)
        return await getRequest(route.getOneUser, query)
    }

    async getOneVideo(query) {
        console.log(route.getOneVideo, query)
        return await getRequest(route.getOneVideo, query)
    }

    async getOneCategory(query) {
        console.log(route.getOneCategory, query)
        return await getRequest(route.getOneCategory, query)
    }

    async getOneNews(query) {
        console.log(route.getAllNews, query)
        return await getRequest(route.getOneNews, query)
    }

    async getTerm(query) {
        console.log(route.getTerm, query)
        return await getRequest(route.getTerm, query)
    }

    async getAbout(query) {
        return await getRequest(route.getAbout, query)
    }

    // post 

    async postVideo(query) {
        console.log(route.postVideo, query)
        return await postRequest(route.postVideo, query)
    }

    async publishVideo(query) {
        console.log(route.publicVideo, query)
        return await postRequest(route.publicVideo, query)
    }

    async postEpisode(query) {
        console.log(route.postEpisode, query)
        return await postRequest(route.postEpisode, query)
    }

    async postOneCategory(query) {
        console.log(route.postOneCategory, query)
        return await postRequest(route.postOneCategory, query)
    }

    async postOneNews(query) {
        console.log(route.postOneNews, query)
        return await postRequest(route.postOneNews, query)
    }

    async adminUpload(query, onUploadProgress) {
        return await postImageRequest(adminUpload, query, onUploadProgress)
    }

    async updateSetting(query) {
        console.log(route.updateSetting, query)
        return await postRequest(route.updateSetting, query)
    }

    async login(data) {
        return await loginRequest(route.login, data)
    }

    getImage = () => {
        return serverURL + "/image/get/";
    }

    
}

export default new API();

// export const getOneCategory = async (query) => {
//     console.log(route.getOneCategory, query)
//     return await getRequest(route.getOneCategory, query)
// }

// export const getOneNew = async (query) => {
//     console.log(route.getOneCategory, query)
//     return await getRequest(route.getOneCategory, query)
// }

// export const getOneUser = async (query) => {
//     console.log(route.getOneUser, query)
//     return await getRequest(route.getOneUser, query)
// }

export const login = async (data) => {
    return await postRequest(route.login, data)
}

