import React, { Component } from 'react'

export class Pagination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_page: this.props.selectPage,
      paginationNumbers : [],
      postPerPage : 10,
    }
  }

  render() {
    const paginationNumbers = [];
    let limit_page = Math.ceil(this.props.totalPageLength / this.state.postPerPage)
    if (limit_page > 10) {
      // show only 10 pagination
      limit_page = 10;
    }
    for (let i = 1; i <= limit_page; i++) {
      paginationNumbers.push(i);
    }
    return (
        <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${this.state.selected_page === 1? "disabled" : ""}`}>
            <button className="page-link" to="#" onClick={() => {
              this.props.onPaginationPress(this.state.selected_page - 1);
              this.setState({selected_page: this.state.selected_page -1 });

            }}>
              
              <span class="ti-arrow-left"></span>
            </button>
          </li>
          {
            paginationNumbers.map(page => {
              return <li className={`page-item ${this.state.selected_page === page? "active" : ""}`}><button className="page-link" href="/" onClick={() => {
                this.props.onPaginationPress(page);
                this.setState({selected_page: page});
              }}>{page}</button></li>
            })
          }
          <li className={`page-item ${this.state.selected_page === paginationNumbers.length? "disabled" : ""}`}>
            <button className="page-link" href="/" onClick={() => {
              this.props.onPaginationPress(this.state.selected_page + 1);
              this.setState({selected_page: this.state.selected_page + 1 });
            }}>
              <span className="ti-arrow-right"></span>
            </button>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Pagination