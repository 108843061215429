import React, { Component } from 'react'
import { Link } from "react-router-dom";
import API from "../../config/api";

import CustomModal from "../Bar/CustomModal";
import Pagination from "../Bar/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class NewsIndex extends Component {

  state = {
    data: null,
    page: 1,
    total: 0,
    news: [],
    image_url: ""
  }

  componentDidMount() {
    this.getParam("success")
  }

  UNSAFE_componentWillMount() {
    
    this.getAllNews();
    this.getImageLink();
    
    
  }

  getParam(name) {
    
    console.log(name)
    let success = window.location.search.split(`${name}=`)[1]
    console.log(success)
    if(success){
      toast.success("Your action is successful", {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      })
   }
  }

  getImageLink() {
    let url = API.getImage();
    this.setState({image_url : url})
  }

  getAllNews = async () => {
    try {
      let query = `page=${this.state.page}`
      let results = await API.getAllNews(query);
      let data = results.data;
      console.log(data)
      
      this.setState({ news : data.news, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onSearch = async (event) => {
    event.preventDefault();
    let value = event.target.value;
    try {
      let query = `page=${this.state.page} + &&s=${value}`
      let results = await API.getAllNews(query);
      
      let data = results.data;
      
      this.setState({ news : data.news, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }
  
  onPaginationPress = (page) => {
    this.state.page = page;
    // this.setState({ page : page})
    this.getAllNews();
    // this.setState({ page : page})
    this.setState({});
  }

  delete = (id) => {
    console.log(id)
  }

  render() {
    return (
      <div className="col-12 ">
          <div className="card card-body card-hover-shadow p-40">
            <h4 className="card-title"><strong>News</strong></h4>
            <ToastContainer />

            <div className="card-body">

            <div className="flexbox mb-20">
                <div className="lookup">
                  <input className="w-200px" type="text" name="s" placeholder="Search" onChange={this.onSearch} />
                </div>

                <div className="btn-toolbar">
                  <div className="btn-group btn-group-sm">
                    {/* <button className="btn" title="Refresh" data-provide="tooltip"><i class="ion-refresh"></i>  </button> */}
                    {/* <button className="btn" title="Add new" data-provide="tooltip"><i class="ion-plus-round"></i></button> */}
                    <Link to="/newsnew" > <button className="btn btn-primary" title="Add new" data-provide="tooltip"> <i className="ion-plus-round"></i></button></Link>
                    {/* <Link to="/user/new" > <button className="btn" title="Add new" data-provide="tooltip"><i class="ion-plus-round"></i>New</button></Link> */}
                  </div>

                  {/* <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
                    <button className="btn dropdown-toggle" data-toggle="dropdown">Export</button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="/">CSV</a>
                      <a className="dropdown-item" href="/">SQL</a>
                      <a className="dropdown-item" href="/">PDF</a>
                      <a className="dropdown-item" href="/">Text</a>
                    </div>
                  </div> */}
                </div>
              </div>
            <div>
          </div>

              <table className="table table-separated">
                <thead>
                  <tr>
                    <th><h6>#</h6></th>
                    <th> <h6>Thumbnail</h6> </th>
                    <th> <h6>Name Kh</h6> </th>
                    <th> <h6>Name En </h6></th>
                    <th> <h6>Description Kh</h6> </th>
                    <th> <h6>Description En</h6> </th>
                    <th> <h6>Created date</h6></th>
                    <th class="text-center w-150px"><h6>Actions</h6></th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.news
                  .map((data, index)=> {
                  return <tr>
                      <th scope="row"><b>{( (this.state.page - 1) * 10) + (index+ 1)}</b></th>
                      <td>
                        <div class="media">
                          <img class="avatar" src={data.thumbnail_url ? data.thumbnail_url : require('../../assets/assets_theme/img/avatar/1.jpg')} alt="..." />
                          <div class="media-body">
                            {/* <p >{data.name_kh}</p> */}
                          </div>
                        </div>
                      </td>
                      <td><strong>{data.title_kh}</strong></td>
                      <td><strong>{data.title_en}</strong></td>
                      <td style={{width: 250, overflow: "hidden" }}>{data.description_kh}</td>
                      <td style={{width: 250, overflow: "hidden" }}>{data.description_en}</td>
                      <td>{data.created_at}</td>
                      <td className="text-right table-actions">
                        <Link className="btn btn-square btn-round btn-primary" title="View" to={`/newsview?id=${data.id}`}><i className="fa fa-eye"></i></Link>
                        <span>&nbsp;</span><Link className="btn btn-square btn-round btn-info" title="Edit" to={`/newsedit?id=${data.id}`}><i class="ti-pencil"></i></Link>
                    
                        {/* <i className="ti-trash table-action hover-danger" data-toggle="modal" data-target="#modal-center" title="Delete" onClick={this.handelInput('djajjius')}></i> */}
                        <span>&nbsp;</span><Link title="Delete" onClick={() => this.setState({data: data})} className="btn btn-square btn-round btn-danger"><i className='ti-trash' data-toggle="modal" data-target="#modal-center" ></i> </Link>
                         

                        {/* <div className="dropdown table-action">
                          <span className="dropdown-toggle no-caret hover-primary" data-toggle="dropdown" title="More"><i class="ti-more-alt rotate-90"></i></span>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="/"><i class="ti-menu-alt"></i> Details</a>
                            <a className="dropdown-item" href="/"><i class="ti-clip"></i> Add file</a>
                            <a className="dropdown-item" href="/"><i class="ti-bar-chart"></i> Performance</a>
                          </div>
                        </div> */}
                      </td>
                    </tr>
                  })
                }
                </tbody>
              </table>
              
              <Pagination 
                onPaginationPress={this.onPaginationPress}
                totalPageLength = {this.state.total}
                selectPage = {this.state.page}
              />
            </div>
          </div>
          {
              this.state.data &&
              <CustomModal 
                data={ this.state.data }  
                title="Do you really want to delete this news?"
                delete = {this.delete}
              />
           }
        </div>
    )
  }
}

export default NewsIndex