import React, { Component } from 'react'
import { Link } from "react-router-dom";

import CustomModal from "../Bar/CustomModal";
import Pagination from "../Bar/Pagination";
import API from '../../config/api';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class CategoryIndex extends Component {

  constructor(props) {
    super(props)
    
    this.getAllCategories = this.getAllCategories.bind(this);
    this.state = {
      data: null,
      page: 1,
      total: 0,
      categories: [],
      image_url: ""
    }
  }

  componentDidMount() {
    this.getParam("success")
  }

  UNSAFE_componentWillMount() {
    this.getAllCategories();
    this.getImageLink();
  }

  getImageLink() {
    let url = API.getImage();
    console.log(url)
    this.state.image_url = url
    this.setState({})
  }

  getParam(name) {
    
    console.log(name)
    let success = window.location.search.split(`${name}=`)[1]
    console.log(success)
    if(success){
      toast.success("Your action is successful", {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      })
   }
  }

  getAllCategories = async () => {
    console.log('get all category')
    try {
      let query = `page=${this.state.page}`
      let results = await API.getAllCategory(query);
      let data = results.data;
      console.log(data)
      console.log('test')
      
      this.setState({ categories : data.category, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onSearch = async (event) => {
    event.preventDefault();
    let value = event.target.value;
    try {
      let query = `page=${this.state.page} + &&s=${value}`
      let results = await API.getAllCategory(query);
      
      let data = results.data;
      
      this.setState({ categories : data.category, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onPaginationPress = (page) => {
    this.state.page = page;
    // this.setState({ page : page})
    this.getAllCategories();
    this.setState({})
  }

  delete = (id) => {
    console.log(id)
  }

  render() {
    return (
      <div className="col-12 ">
        <ToastContainer />
          <div className="card card-body card-hover-shadow p-40">
            <h4 className="card-title"><strong>Category Lists</strong></h4>

            <div className="card-body">

            <div className="flexbox mb-20">
                <div className="lookup">
                  <input className="w-200px" type="text" name="s" placeholder="Search" onChange={this.onSearch} />
                </div>

                <div className="btn-toolbar">
                  <div className="btn-group btn-group-sm">
                    {/* <button className="btn" title="Refresh" data-provide="tooltip"><i class="ion-refresh"></i>  </button> */}
                    {/* <button className="btn" title="Add new" data-provide="tooltip"><i class="ion-plus-round"></i></button> */}
                    <Link to="/categorynew" > <button className="btn btn-primary" title="Add new" data-provide="tooltip"> <i className="ion-plus-round"></i></button></Link>
                    {/* <Link to="/user/new" > <button className="btn" title="Add new" data-provide="tooltip"><i class="ion-plus-round"></i>New</button></Link> */}
                  </div>

                  {/* <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
                    <button className="btn dropdown-toggle" data-toggle="dropdown">Export</button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="/">CSV</a>
                      <a className="dropdown-item" href="/">SQL</a>
                      <a className="dropdown-item" href="/">PDF</a>
                      <a className="dropdown-item" href="/">Text</a>
                    </div>
                  </div> */}
                </div>
              </div>
            <div>
          </div>

              <table className="table table-separated">
                <thead>
                  <tr>
                    <th><h6>#</h6></th>
                    <th> <h6>Thumbnail</h6> </th>
                    <th> <h6>Name Kh</h6> </th>
                    <th> <h6> Name En</h6> </th>
                    <th> <h6>Created date</h6></th>
                    <th class="text-center w-150px"><h6>Actions</h6></th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.categories
                  .map((category, index)=> {
                  return <tr>
                      <th scope="row"><b>{( (this.state.page - 1) * 10) + (index + 1)}</b></th>
                      {/* <td><img style={{width: 30, height: 30, borderRadius: 30}} src={category.thumbnail_url} alt="video thumbnail" /></td> */}
                      <td>
                        <div class="media">
                          <img class="avatar" src={category.thumbnail_url ? category.thumbnail_url : require('../../assets/assets_theme/img/avatar/1.jpg')} alt="..." />
                          {/* <img class="avatar" src={category.thumbnail_url ? category.thumbnail_url : require('../../assets/assets_theme/img/avatar/1.jpg')} alt="..." /> */}
                          <div class="media-body">
                            {/* <p >{category.name_kh}</p> */}
                          </div>
                        </div>
                      </td>
                      <td><strong>{category.name_kh}</strong></td>
                      <td><strong>{category.name_en}</strong></td>
                      <td>{category.created_at}</td>
                      <td className="text-right table-actions">
                        <Link title='View' class="btn btn-square btn-round btn-primary" to={`/categoryview?id=${category.id}`}><i class="fa fa-eye"></i></Link>
                        <span>&nbsp;</span><Link title='Edit' class="btn btn-square btn-round btn-info" to={`/categoryedit?id=${category.id}`}><i class="ti-pencil"></i></Link>
                        <span>&nbsp;</span><Link title='Delete' class="btn btn-square btn-round btn-danger"><i data-toggle="modal" data-target="#modal-center" onClick={() => this.setState({data: category})} class="fa fa-trash"></i></Link>

                        {/* <Link className="table-action hover-primary" title="View" to={`/categoryview?id=${category.id}`}><i className="fa fa-eye"></i></Link>
                        <Link className="table-action hover-primary" title="Edit" to={`/categoryedit?id=${category.id}`}><i class="ti-pencil"></i></Link> */}
                      
                        {/* <i className="ti-trash table-action hover-danger" data-toggle="modal" data-target="#modal-center" title="Delete" onClick={() => this.setState({data: category})}></i>  */}

                        {/* <div className="dropdown table-action">
                          <span className="dropdown-toggle no-caret hover-primary" data-toggle="dropdown" title="More"><i class="ti-more-alt rotate-90"></i></span>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="/"><i class="ti-menu-alt"></i> Details</a>
                            <a className="dropdown-item" href="/"><i class="ti-clip"></i> Add file</a>
                            <a className="dropdown-item" href="/"><i class="ti-bar-chart"></i> Performance</a>
                          </div>
                        </div> */}
                      </td>
                    </tr>
                  })
                }
                </tbody>
              </table>
              <Pagination 
                onPaginationPress = {this.onPaginationPress}
                totalPageLength = {this.state.total}
                selectPage = {this.state.page}
              />
            </div>
          </div>
          {
              this.state.data &&
              <CustomModal 
                data={ this.state.data }  
                title="Do you really want to delete this category?"
                delete = {this.delete}
              />
           }
        </div>
    )
  }
}

export default CategoryIndex