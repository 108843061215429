import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

export class UserReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nb_days: 15,
      data: [],
    }
  }

  getRamdom() {
    return Math.floor(Math.random() * 1000) + 3000
  }

  getDay(i) {
    return moment().subtract(i, 'day').format("DD")
  }

  componentWillMount() {
    let tempArray = []
    for (let i = this.state.nb_days; i > 0; i--) {
      tempArray.push({
        key: i,
        active_users: this.getRamdom(),
        name: this.getDay(i)
      })
    }
    
    this.setState({data: tempArray})
  }

  render() {
    return (
      <div class="col-lg-12">
        <div className=''>
          <div className='row'>
          <div class="col-md-6 col-lg-4">
            <div class="card card-body">
              <div class="flexbox">
                <div data-provide="sparkline" data-width="100" data-fill-color="false" data-line-width="2">1,4,3,7,6,4,8,9,6,8,12</div>
                <div class="text-right">
                  <span class="fw-400">New Users</span><br />
                  <span>
                    <i class="ti-angle-up text-success"></i>
                    <span class="fs-18 ml-1">113</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card card-body">
              <div class="flexbox">
                <div data-provide="sparkline" data-type="bar" data-bar-color="#f2a654">1,4,3,7,6,4,8,9,6,8,12</div>
                <div class="text-right">
                  <span class="fw-400">Active Users</span><br />
                  <span>
                    <i class="ti-angle-up text-success"></i>
                    <span class="fs-18 ml-1">80</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card card-body">
              <div class="flexbox">
                <div data-provide="sparkline" data-type="discrete" data-width="50" data-line-color="#926dde">1,4,3,7,6,4,8,9,6,8,12</div>
                <div class="text-right">
                  <span class="fw-400">Total Users</span><br />
                  <span>
                    <i class="ti-angle-up text-success"></i>
                    <span class="fs-18 ml-1">80</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className='row'>
          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Top Country</h5>
                <ul class="card-controls">
                  <li class="dropdown">
                    <a data-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item active" href="#">Today</a>
                      <a class="dropdown-item" href="#">Yesterday</a>
                      <a class="dropdown-item" href="#">Last week</a>
                      <a class="dropdown-item" href="#">Last month</a>
                    </div>
                  </li>
                  <li><a class="card-btn-reload" href="../assets/data/general.html" title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li>
                </ul>
              </div>

              <div class="card-body card-body">
                <div class="text-center py-20">
                  <div data-provide="peity" data-type="donut" data-size="150" data-inner-radius="55" data-fill="#efb3e6,#ffdf7c,#b2def7">9,6,5</div>
                </div>

                <ul class="list-inline">
                  <li class="flexbox mb-1">
                    <div>
                      <span class="badge badge-dot badge-lg mr-1" style={{backgroundColor : '#efb3e6'}}></span>
                      <span>Cambodia</span>
                    </div>
                    <div>953</div>
                  </li>

                  <li class="flexbox mb-1">
                    <div>
                      <span class="badge badge-dot badge-lg mr-1" style={{backgroundColor : '#ffdf7c'}} ></span>
                      <span>Lao</span>
                    </div>
                    <div>813</div>
                  </li>

                  <li class="flexbox">
                    <div>
                      <span class="badge badge-dot badge-lg mr-1" style={{backgroundColor : '#b2def7'}} ></span>
                      <span>Thailand</span>
                    </div>
                    <div>369</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Gender</h5>

                <ul class="card-controls">
                  <li class="dropdown">
                    <a data-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item active" href="#">Today</a>
                      <a class="dropdown-item" href="#">Yesterday</a>
                      <a class="dropdown-item" href="#">Last week</a>
                      <a class="dropdown-item" href="#">Last month</a>
                    </div>
                  </li>
                  <li><a class="card-btn-reload" href="../assets/data/general.html" title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li>
                </ul>
              </div>

              <div class="card-body card-body">
                <div class="text-center py-20">
                  <div data-provide="peity" data-type="pie" data-size="150" data-inner-radius="55" data-fill="#33cabb,#48b0f7,#fdd501">9,6,5</div>
                </div>

                <ul class="flexbox flex-justified text-center mt-30">
                  <li class="br-1 botder-light">
                    <div class="fs-20 text-primary">953</div>
                    <small>Male</small>
                  </li>

                  <li class="br-1 botder-light">
                    <div class="fs-20 text-info">813</div>
                    <small>Female</small>
                  </li>

                  <li>
                    <div class="fs-20 text-yellow">369</div>
                    <small>Other</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Top City</h5>

                <ul class="card-controls">
                  <li class="dropdown">
                    <a data-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item active" href="#">Today</a>
                      <a class="dropdown-item" href="#">Yesterday</a>
                      <a class="dropdown-item" href="#">Last week</a>
                      <a class="dropdown-item" href="#">Last month</a>
                    </div>
                  </li>
                  <li><a class="card-btn-reload" href="../assets/data/general.html" title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li>
                </ul>
              </div>

              <div class="card-body card-body">
                <p>Synergistically repurpose market positioning functionalities via top-line.repurpose market positioningrepurpose market positioning</p>
                <div class="flexbox mt-70">
                  <div data-provide="peity" data-type="bar" data-height="130" data-width="90" data-fill="#33cabb,#48b0f7,#fdd501">953,813,369</div>

                  <ul class="list-inline align-self-end text-muted text-right mb-0">
                    <li>Phnom Penh <span class="badge badge-ring badge-primary ml-2"></span></li>
                    <li>Battambang <span class="badge badge-ring badge-info ml-2"></span></li>
                    <li>Kandal <span class="badge badge-ring badge-yellow ml-2"></span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card'>
          <div class="card-header">
            <h5 class="card-title">Active users Previous Days</h5>

            <ul class="card-controls">
              <li class="dropdown">
                <a data-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item active" href="#">Today</a>
                  <a class="dropdown-item" href="#">Yesterday</a>
                  <a class="dropdown-item" href="#">Last week</a>
                  <a class="dropdown-item" href="#">Last month</a>
                </div>
              </li>
              {/* <li><a class="card-btn-reload" href="../assets/data/general.html" title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li> */}
            </ul>
          </div>

          <div class="card-body card-body">
        {
          this.state.data.length != 0 && 
          <div  style={{width: '100%', height: 300 }}>
          <ResponsiveContainer debounce={300} width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={this.state.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="active_users" fill="#8884d8" background={{ fill: '#eee' }} />
            </BarChart>
          </ResponsiveContainer>
          </div>
        }
          </div>
        </div>
        
        <div class="card">
            <div class="card-header">
              <h5 class="card-title"><strong>User Report</strong></h5>
              <a class="btn btn-xs btn-light" href="#">See all</a>
            </div>

            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Plan</th>
                  <th>User</th>
                  <th>Price</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Individual</td>
                  <td><a class="hover-primary" href="#">Ranian</a></td>
                  <td>$9</td>
                  <td>25 July</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Individual</td>
                  <td><a class="hover-primary" href="#">Makein</a></td>
                  <td>$9</td>
                  <td>21 July</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Business</td>
                  <td><a class="hover-primary" href="#">Suhan</a></td>
                  <td>$19</td>
                  <td>16 July</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Individual</td>
                  <td><a class="hover-primary" href="#">Inamu</a></td>
                  <td>$9</td>
                  <td>12 June</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Enterprise</td>
                  <td><a class="hover-primary" href="#">Finak</a></td>
                  <td>$49</td>
                  <td>09 Jan</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Business</td>
                  <td><a class="hover-primary" href="#">Kurady</a></td>
                  <td>$19</td>
                  <td>20 Dec 2015</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>  
    )
  }
}

export default UserReport