import axios from "axios";

export function postRequest(url, data) {
    const items = JSON.parse(localStorage.getItem('login'));
    return axios({
      method: 'post',
      url: url,
      headers: {
        'Content-Type' : 'application/json',
        'token' :  items.data.token
        // 'token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjEsInBhc3N3b3JkX2xldmVsIjoxfSwiaWF0IjoxNzE5OTgxNjQ1fQ.6cZfF5rM-p5Dnh_V7IHf60YU1PHKx45-j6BXx_W6d7c" 
      }, 
      data: data
    })
      .then((res) => res.data)
}

export function postImageRequest(url, data, onUploadProgress) {
  const items = JSON.parse(localStorage.getItem('login'));
  return axios({
    method: 'post',
    url: url,
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onUploadProgress(percentCompleted)
      }
    },
    headers: {
      'Content-Type' : 'multipart/form-data',
      'token' :  items.data.token
    }, 
    data
  })
    .then((res) => res.data)
}

export function loginRequest(url, data) {
  // const items = JSON.parse(localStorage.getItem('login'));
  return axios({
    method: 'post',
    url: url,
    headers: {
      'Content-Type' : 'application/json',
      // 'token' :  items.data.token
      // 'token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjEsInBhc3N3b3JkX2xldmVsIjoxfSwiaWF0IjoxNzE5OTgxNjQ1fQ.6cZfF5rM-p5Dnh_V7IHf60YU1PHKx45-j6BXx_W6d7c" 
    }, 
    data: data
  })
    .then((res) => res.data)
}