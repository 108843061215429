import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import API from "../../config/api";

export class NewsEdit extends Component {

    constructor(props) {
        super(props)
      
        this.state = {
          news_id: 1,
          news: [],
          percentage: 0,
          disable: true,
          thumbnail: "",
          image_url: ""
        }
    }
      UNSAFE_componentWillMount() {
        this.getParam("id");
        this.getOneNews();
        this.getImage();
      }

      getParam(name) {
        let id = window.location.search.split(`${name}=`)[1]
        this.state.news_id = id;
        // this.setState({category_id : id})
        this.setState({})
        // return 
      }

      getImage() {
        let url = API.getImage();
        this.setState({image_url : url})
      }
    
      getOneNews = async () => {
        try {
          let query = `news_id=${this.state.news_id}`
          let results = await API.getOneNews(query);
    
          let data = results.data;
          console.log(data)
          this.setState({ 
            news : data ,
            thumbnail: data.thumbnail_url
          });
    
        } catch (error) {
          console.log(error)
        }
      }

      onUploadProgress = (percentage) => {
        this.setState({ percentage: percentage})
        if (percentage ===100) {
          this.setState({ disable: false })
        }
      }

      onChangeFile = async (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        let name = event.target.name;
        const formData = new FormData();
        formData.append("file", file);
        
        try {
          let results = await API.adminUpload(
            formData,
            this.onUploadProgress
          );
          console.log(results)
          
          if (results){
            this.setState({ news: {
              ...this.state.news,
              [name]: results.filename,
            }})
          }
    
        } catch (error) {
          console.log(error)
        }
      }
      
      handelInput = (event) => {
        event.preventDefault();
        let value = event.target.value;
        let name = event.target.name;
        
        this.setState({ news: {
          ...this.state.news,
          [name]: value
        }})
      }
    
      handleSubmit = async (event) => {
        event.preventDefault();
        try {
          let results = await API.postOneNews(
            this.state.news
          );
          if (results.result === 1){
            this.setState({ success: results.result })
          }
    
        } catch (error) {
          console.log(error)
        }
      }

  render() {
    return (
        <div className="col-12">
            {this.state.success && (
              <Navigate to="/newsindex?success=true" replace={true} />
            )}
            <form className="card" onSubmit={this.handleSubmit}>
                <h4 className="card-title"><strong>News</strong></h4>

                <div className="card-body row">

                <div className="col-md-6">
                      <div className="form-group">
                          <label className="require" for="input-required">Title Kh</label>
                          <input type="text" className="form-control" id="input-required" value={this.state.news.title_kh} onChange={this.handelInput} name='title_kh' />
                      </div>
                      <div className="form-group">
                          <label>Description Kh</label>
                          <textarea className="form-control" type="text" value={this.state.news.description_kh} onChange={this.handelInput} name='description_kh' />
                      </div>
                      
                    {/* <div className="form-group">
                        <label for="inputState" className="col-form-label">Parent</label>
                        <select id="inputState" className="form-control">
                        <option>Short Film</option>
                        <option>Funny</option>
                        <option>Love story</option>
                        <option>Sad story</option>
                        </select>
                    </div> */}
                    
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                            <label className="require" for="input-required">Title en</label>
                            <input type="text" className="form-control" id="input-required" value={this.state.news.title_en} onChange={this.handelInput} name='title_en' />
                      </div>
                      <div className="form-group">
                          <label>Description En</label>
                          <textarea className="form-control" type="text" value={this.state.news.description_en} onChange={this.handelInput} name='description_en' />
                      </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Thumbnail</label>
                        <img className='h-300px w-fullscreen' src={this.state.image_url + this.state.thumbnail} alt="..." />
                        <br />
                        <input type="file" name='thumbnail_url' data-provide="dropify" onChange={this.onChangeFile} />
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" style={{width: this.state.percentage + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>{this.state.percentage + "%"}</strong></div>
                          {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div> */}
                        </div>
                    </div>
                    </div>
                </div>

                <footer className="card-footer text-right">
                    <Link className="btn btn-secondary" to="/newsindex">Cancel</Link>
                    <button className="btn btn-primary" type="submit" >Submit</button>
                </footer>
            </form>
        </div>
    )
  }
}

export default NewsEdit