import React from 'react'
import { Link } from 'react-router-dom'

function SubscriptionReport() {
  return (
    <div class="col-lg-12">
        <div class="card">
          <div className='row'>
          <div class="col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="flexbox">
                  <h5>Premium</h5>
                  <div class="dropdown">
                    <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti-more-alt rotate-90"></i></span>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#"><i class="ion-android-list"></i> Details</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-add"></i> Add new</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-refresh"></i> Refresh</a>
                    </div>
                  </div>
                </div>

                <div class="text-center my-2">
                  <div class="fs-60 fw-400 text-primary">34</div>
                  <span class="fw-400 text-muted">Package</span>
                </div>
              </div>

              <div class="card-body bg-lighter fw-400 py-12">
                <span class="text-muted mr-1">Completed:</span>
                <span class="text-dark">16</span>
              </div>

              <div class="progress mb-0">
                <div class="progress-bar bg-primary" role="progressbar" style={{width: "45%", height: "3px"}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>




          <div class="col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="flexbox">
                  <h5>Gold</h5>
                  <div class="dropdown">
                    <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti-more-alt rotate-90"></i></span>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#"><i class="ion-android-list"></i> Details</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-add"></i> Add new</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-refresh"></i> Refresh</a>
                    </div>
                  </div>
                </div>

                <div class="text-center my-2">
                  <div class="fs-60 fw-400 text-warning">15</div>
                  <span class="fw-400 text-muted">Package</span>
                </div>
              </div>

              <div class="card-body bg-lighter fw-400 py-12">
                <span class="text-muted mr-1">Yesterday's overdue:</span>
                <span class="text-dark">9</span>
              </div>

              <div class="progress mb-0">
                <div class="progress-bar bg-warning" role="progressbar" style={{width: "35%", height: "3px"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>



          <div class="col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="flexbox">
                  <h5>Silver</h5>
                  <div class="dropdown">
                    <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti-more-alt rotate-90"></i></span>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#"><i class="ion-android-list"></i> Details</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-add"></i> Add new</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-refresh"></i> Refresh</a>
                    </div>
                  </div>
                </div>

                <div class="text-center my-2">
                  <div class="fs-60 fw-400 text-info">82</div>
                  <span class="fw-400 text-muted">Package</span>
                </div>
              </div>

              <div class="card-body bg-lighter fw-400 py-12">
                <span class="text-muted mr-1">Closed today:</span>
                <span class="text-dark">28</span>
              </div>

              <div class="progress mb-0">
                <div class="progress-bar bg-info" role="progressbar" style={{width: "30%", height: "3px"}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>



          <div class="col-md-6 col-xl-3">
            <div class="card">
              <div class="card-body">
                <div class="flexbox">
                  <h5>Normal</h5>
                  <div class="dropdown">
                    <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti-more-alt rotate-90"></i></span>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="#"><i class="ion-android-list"></i> Details</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-add"></i> Add new</a>
                      <a class="dropdown-item" href="#"><i class="ion-android-refresh"></i> Refresh</a>
                    </div>
                  </div>
                </div>

                <div class="text-center my-2">
                  <div class="fs-60 fw-400 text-dark">39</div>
                  <span class="fw-400 text-muted">Package</span>
                </div>
              </div>

              <div class="card-body bg-lighter fw-400 py-12">
                <span class="text-muted mr-1">Implemented:</span>
                <span class="text-dark">13</span>
              </div>

              <div class="progress mb-0">
                <div class="progress-bar bg-dark" role="progressbar" style={{width: "60%", height: "3px"}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>

          </div>

          <div class="card-header">
            <h5 class="card-title"><strong>SubScription</strong></h5>
            <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
              {/* <Link class="btn btn-xs btn-light" to=''>See all</Link> */}
              <button className="btn btn-info dropdown-toggle" data-toggle="dropdown">Export</button>
              <div className="dropdown-menu dropdown-menu-right">
                  <Link class="dropdown-item"  ><i class="fa fa-file-zip-o"></i> Zip</Link>
                  <Link class="dropdown-item"  ><i class="fa fa-file-pdf-o"></i> PDF</Link>
                  <Link class="dropdown-item"  ><i class="fa fa-file-excel-o"></i> Excel</Link>
              </div>
            </div>
          </div>

          <table class="table table-hover table-has-action">
            <thead>
              <tr>
                <th>#</th>
                <th>Product</th>
                <th>Amount (USD)</th>
                <th>Status</th>
                <th class="w-80px text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>153</td>
                <td>iPhone</td>
                <td>$699</td>
                <td><span class="label label-pill label-default">Processing</span></td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Send for packaging"><i class="ti-package"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-close"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>435</td>
                <td>iPad pro</td>
                <td>$849</td>
                <td><span class="label label-pill label-default">Processing</span></td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Send for packaging"><i class="ti-package"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-close"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>653</td>
                <td>Microsoft surface</td>
                <td>$1,699</td>
                <td><span class="label label-pill label-info">Sent</span></td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Send for packaging"><i class="ti-package"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-close"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>345</td>
                <td>Galaxy S7 edge</td>
                <td>$729</td>
                <td><span class="label label-pill label-danger">Canceled</span></td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Send for packaging"><i class="ti-package"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-close"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>906</td>
                <td>128G SD Card</td>
                <td>$43</td>
                <td><span class="label label-pill label-success">Delivered</span></td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Send for packaging"><i class="ti-package"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-close"></i></a>
                  </nav>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
  )
}

export default SubscriptionReport