import React, {Component} from "react";
import { Link } from "react-router-dom";
// import Roles from "../Bar/Roles";

class UserNew extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       imageUrl: 'https://img.freepik.com/free-photo/side-view-ofserious-man_23-2148946213.jpg?size=626&ext=jpg&ga=GA1.1.1518270500.1717372800&semt=ais_user'
    }
  }

  render() {
    return (

          <div className="col-12">
            <form className="card">
              <h4 className="card-title fw-400">New User</h4>
              <br />

              <div className="card-body">
                <div className="flexbox gap-items-4">
                  <img className="avatar avatar-xl" src={this.state.imageUrl} alt="..." />
                  {/* <img className="avatar avatar-xl" src={require('../../assets/assets_theme/img/avatar/2.jpg')} alt="..." /> */}
                  <div className="flex-grow">
                    <h5>Hossein Shams</h5>
                    <div className="d-flex flex-column flex-sm-row gap-items-2 gap-y mt-16">
                      <div className="file-group file-group-inline">
                        <button className="btn btn-sm btn-w-lg btn-outline btn-round btn-secondary file-browser" type="button">Change Picture</button>
                        <input type="file" />
                      </div>

                      <a className="btn btn-sm btn-w-lg btn-outline btn-round btn-danger" href="/">Delete Picture</a>
                    </div>

                  </div>
                </div>

                <hr />

                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Fist Name</label>
                        <input type="text" className="form-control" id="input-required" />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Last Name</label>
                        <input type="text" className="form-control" id="input-required" />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Email</label>
                        <input className="form-control" type="text" />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Address</label>
                        <input className="form-control" type="text" />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="text-fader">Gender</label>
                    <select className="form-control" title="&#xA0;" data-provide="selectpicker">
                      <option> Male</option>
                      <option>Female</option>
                      <option selected>Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Role</label>
                    <select className="form-control" title="&#xA0;" data-provide="selectpicker">
                      <option selected> Editor</option>
                      <option>Admin</option>
                      <option>Guess</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label className="text-fader">Phone Number 1</label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Phone Number 2</label>
                    <input className="form-control" type="text" />
                  </div>
                </div>

                <hr />
              </div>
              {/* <Roles /> */}
              {/* <div className="card-body">
                <button className="btn btn-block btn-round btn-bold btn-primary" type="submit">Save</button>
              </div> */}
              <footer class="card-footer text-right">
                {/* <button class="btn btn-secondary" type="reset">Cancel</button> */}
                <Link class="btn btn-secondary" to={'/userindex'} >Cancel</Link>
                <button class="btn btn-primary" type="submit">Submit</button>
              </footer>

            </form>
          </div>
    );
  }
}

export default UserNew;
