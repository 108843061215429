import React, {Component} from "react";
import { Link } from "react-router-dom";
import API from "../../config/api";

import CustomModal from "../Bar/CustomModal";
import Pagination from "../Bar/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class videoindex extends Component {
  state = {
    data: null,
    page: 1,
    total: 0,
    videos: [],
    image_url: null
  }

  UNSAFE_componentWillMount() {
    this.getAllVideos();
    this.getImageLink();
  }

  componentDidMount() {
    this.getParam("success")
  }

  getImageLink() {
    let url = API.getImage();
    console.log(url)
    this.state.image_url = url
    this.setState({})
  }

  getParam(name) {
    
    let success = window.location.search.split(`${name}=`)[1]
    if(success){
      console.log(success,'djajksdjjak')
      toast.success("Your action is successful", {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      })
   }
  }

  getAllVideos = async () => {
    try {
      let query = `page=${this.state.page}`
      let results = await API.getAllVideos(query);

      let data = results.data;
      console.log(data)
      
      this.setState({ videos : data.videos, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onSearch = async (event) => {
    event.preventDefault();
    let value = event.target.value;
    try {
      let query = `page=${this.state.page} + &&s=${value}`
      let results = await API.getAllVideos(query);
      
      let data = results.data;
      
      this.setState({ videos : data.videos, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  sort = () => {
    alert('In construction')
  }

  onPaginationPress = (page) => {
    this.state.page = page;
    // this.setState({ page : page})
    this.getAllVideos();
    this.setState({});
  }

  delete = (id) => {
    console.log(id)
  }

  render() {
    return (
         <div className="col-12 ">
          <ToastContainer />
            <div className="card card-body card-hover-shadow p-40">
              <h4 className="card-title"><strong>Video Lists</strong></h4>
              <div className="card-body">
              <div className="flexbox mb-20">
                  <div className="lookup">
                    <input className="w-200px" type="text" name="s" placeholder="Search" onChange={this.onSearch}  />
                  </div>

                  <div className="btn-toolbar">
                    <div className="btn-group btn-group-sm">
                      {/* <Link to="/videonew" className="btn" title="Add new" data-provide="tooltip" > <i className="ion-plus-round"></i></Link> */}
                      <Link to="/videoedit" className="btn btn-primary" title="Add new" data-provide="tooltip" > <i className="ion-plus-round"></i></Link>
                      {/* <button class="btn btn-float btn-primary"><i class="ti-pencil"></i></button> */}
                    </div>

                    <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
                      <button className="btn btn-info dropdown-toggle" data-toggle="dropdown">Export</button>
                      <div className="dropdown-menu dropdown-menu-right">
                      <Link class="dropdown-item" onClick={this.sort} ><i class="fa fa-file-zip-o"></i> Zip</Link>
                        <Link class="dropdown-item" onClick={this.sort} ><i class="fa fa-file-pdf-o"></i> Image</Link>
                        <Link class="dropdown-item" onClick={this.sort} ><i class="fa fa-file-excel-o"></i> Video</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="table table-separated">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Thumbnail</th>
                      <th>Title Kh</th>
                      <th>Title En</th>
                      {/* <th>Description Kh</th>
                      <th>Description En</th> */}
                      <th>Total price</th>
                      <th>Discount</th>
                      <th>Total rating</th>
                      <th>Rating</th>
                      <th>Release Date</th>
                      <th className="text-center w-150px">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.videos
                      .map((video, index)=> {
                      return <tr>
                        <th scope="row">{( (this.state.page - 1) * 10) + (index+ 1)}</th>
                        {/* <td><img style={{width: 30, height: 30, borderRadius: 30}} src={video.thumbnail_url} alt="video thumbnail" /></td> */}
                        <td>
                          <div class="media">
                            <img class="avatar" src={video.thumbnail_url ? video.thumbnail_url : require('../../assets/assets_theme/img/avatar/1.jpg')} alt="..." />
                            <div class="media-body">
                              {/* <p >{video.title_kh}</p> */}
                            </div>
                          </div>
                        </td>
                        <td>{video.title_kh}</td>
                        <td>{video.title_en}</td>
                        {/* <td>{video.description_kh}</td>
                        <td>{video.description_en}</td> */}
                        <td className="w-100px">{video.total_price}</td>
                        <td>{video.discount}</td>
                        <td className="w-100px">{video.total_rating}</td>
                        <td>{video.rating}</td>
                        <td>{video.created_at}</td>
                        <td className="text-right table-actions">
                          {/* <Link title="View" class="btn btn-square btn-round btn-primary" to={`/userview?id=${user.id}`}><i className="fa fa-eye"></i></Link> */}
                          <Link className="btn btn-square btn-round btn-primary" title="View" to={`/videoview?id=${video.id}`}><i className="fa fa-eye"></i></Link>
                          <span>&nbsp;</span><Link className="btn btn-square btn-round btn-info" title="Edit" to={`/videoedit?id=${video.id}`}><i className="ti-pencil"></i></Link>
                        
                          <span>&nbsp;</span><Link title="Delete" onClick={() => this.setState({data: video})}><i className="btn btn-square btn-round btn-danger" data-toggle="modal" data-target="#modal-center" ></i> </Link>

                          {/* <div className="dropdown table-action">
                            <span className="dropdown-toggle no-caret hover-primary" data-toggle="dropdown" title="More"><i class="ti-more-alt rotate-90"></i></span>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="/"><i class="ti-menu-alt"></i> Details</a>
                              <a className="dropdown-item" href="/"><i class="ti-clip"></i> Add file</a>
                              <a className="dropdown-item" href="/"><i class="ti-bar-chart"></i> Performance</a>
                            </div>
                          </div> */}
                        </td>
                      </tr>
                      
                    })
                  }
                  </tbody>
                </table>
                <Pagination 
                  onPaginationPress={this.onPaginationPress}
                  totalPageLength = {this.state.total}
                  selectPage = {this.state.page}
              />
              </div>
            </div>

          {/* Modal */}
          {
              this.state.data &&
              <CustomModal 
                data={ this.state.data } 
                title="Do you really want to delete this video?"
                delete = {this.delete} 
              />
           }
       </div>
    );
  }
}

export default videoindex;
