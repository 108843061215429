import React, { Component } from 'react'
import API from "../../config/api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

class TermAndCondition extends Component {

  state = {
    id: 2,
    name: '',
    description: ''
  }

  UNSAFE_componentWillMount() {
    this.getTerm();
  }

  getTerm = async () => {
    try {
      let results = await API.getTerm("");
      let data = results.data;
      
      this.setState({ id : data.id, name: data.name, description: data.description });

    } catch (error) {
      console.log(error)
    }
  }

  handleChange = (value) => {
    this.setState({ description: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let results = await API.updateSetting({
        "name": this.state.name,
        "description": this.state.description
      });
      
      if (results.result === 1){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
      }
      console.log(results);

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <div className="col-12">
        <ToastContainer />
              <form className="card" onSubmit={this.handleSubmit}>
                  <h4 className="card-title"><strong>Term and condition</strong></h4>
                    <div className="card-body row">
                      <div class="card col-12">
                          {/* <h4 className="card-title"><strong>Description</strong></h4> */} 

                          <div className="card-body" >
                          <ReactQuill 
                            theme="snow" 
                            modules={modules}
                            style={{"height" : "300px"}} 
                            value={this.state.description} 
                            onChange={this.handleChange} />
                        </div>
                      </div>
                    </div>
                <footer class="card-footer text-right">
                  <button class="btn btn-secondary" type="reset">Cancel</button>
                  <button class="btn btn-primary" type="submit">Submit</button>
                </footer>
              </form>
            </div>
    )
  }
}

export default TermAndCondition