import React, {Component} from "react";
import { Link, Navigate } from "react-router-dom";
import deleteGif from '../../../src/assets/assets_theme/img/trash-can.gif'
import Select from 'react-select';
import API from "../../config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class VideoEdit extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      data: null,
      count: 1,
      video_id : null, 
      episode_id: 0,
      // video_id : this.getParam("id"), 
      video: {
        id: null,
        title_kh : "",
        title_en : "",
        title_ch : "",
        description_kh: "",
        description_en: "",
        description_ch: "",
        release_date: "",
        total_price: 0,
        discount: null,
        thumbnail_url : "",
        preview_video_url: "",
        rating: "",
        total_rating: "",
        options: [],
        is_public: 0,
        preview_thumbnail_progress: 0,
        preview_video_progress: 0
      },
      all_tags: [],
      selected_tag: [],
      isMulti: false,
      all_eposides: [],
      image_url: null,
      
      disable: true,
      edit: false,
      publish: true,
      limit_publish: 1,
      
      success: null, 
      error: null,
      tags: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.state.selected_tag = this.state.isMulti? [] : {};
    if(this.getParam("id")) {
      this.getOneVideo();
      this.setState({edit: true})
    } else {
      setTimeout(() => {
        this.onAddChild()
        this.setState({})
      }, 300);
    }
    this.getAllCategories();
    this.getImageLink();
    if(this.getParam('success')) {
      toast.success("Your action is successful", {
        data: {
          title: "Success toast",
          text: "This is a success message",
        },
      })
    }
  };
  

  getImageLink() {
    let url = API.getImage();
    this.state.image_url = url
    this.setState({})
  }

  getParam(name) {
    let id = window.location.search.split(`${name}=`)[1]
    console.log(id)
    this.state.video_id = id
    // this.setState({category_id : id})
    this.setState({})
    return id;
  }

  getAllCategories = async () => {
    try {
      let query = ''
      let results = await API.getAllCategory(query);
      let data = results.data;

      let tags = data.category.map(tag => {
        return {
          value: tag,
          label: tag.name_en,
          id: tag.id
        }
      });
      
      this.setState({ all_tags : tags });

    } catch (error) {
      console.log(error)
    }
  }

  getOneVideo = async () => {
    try {
      let query = `video_id=${this.state.video_id}`
      let results = await API.getOneVideo(query);

      let data = results.data;
      console.log(data)

      let tag = data.tag.map(t => {
        return {
          value: t,
          label: t.name_en,
          id: t.id
        }
      });

      this.setState({ video : data.video, selected_tag: this.state.isMulti? tag : tag[0], all_eposides: data.episode });
      if(data.video.is_public) {
        this.state.publish = false
        this.setState({})
      }
      // this.setState({ video : data.video, tags_options: tag, all_tags: tag, all_eposides: data.episode });

    } catch (error) {
      console.log(error)
    }
  }

  episodeView(data) {
    return <div className="card">
    <div className="card-body row">
  
      <div className="col-md-6">
        <div className="form-group">
            <label className="require" for="input-required">Title Kh</label>
            <input type="text" className="form-control" id="namekh1" 
              ref = {ref => data.ref = ref}
              onChange={(event) => {
                data.title_kh = event.target.value;
                this.setState({})
              }} 
              value={data.title_kh}
              />
        </div>
        <div className="form-group">
            <label className="require" for="input-required">Description Kh</label>
            <textarea type="text" className="form-control" id="input-required"
              onChange={(event) => {
                data.description_kh = event.target.value;
                this.setState({})
              }} 
              value={data.description_kh}
            />
        </div>
        
        <div className="form-group">
          <label className="require" for="input-required">Price for each episode</label>
          <small className="sidetitle">E.g. $ 999.99</small>
          <input type="number" className="form-control"  
            onChange={(event) => {
              data.price = event.target.value;
              this.setState({})
            }} 
            value={data.price}
          />
        </div>
        <div className="form-group">
          <label className="require" for="input-required">Release_date</label>
          <input className="form-control" type="datetime-local" name="release_date" 
            onChange={(event) => {
              data.release_date = event.target.value;
              this.setState({})}
            }
            value={data.release_date} 
          />
        </div>
        <div className="form-group">
          <label className="require" for="input-required">Thumbnail</label>
           
          <img 
              className={data.thumbnail_url? "h-300px w-fullscreen" : ""} 
              src={data.thumbnail_url}
          /> 

          <input type="file" data-provide="dropify" name='thumbnail_url' onChange={(e) => this.onImageChange(e, data)} />
          {/* <div class="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" 
            style={{width: data.img_upload_progress + "%"}}></div>
          </div> */}
          <div class="progress">
            {/* <div class="progress-bar" role="progressbar" style="width: 50%; height: 16px;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>90%</strong></div> */}
            <div class="progress-bar" role="progressbar" style={{width: data.img_upload_progress + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>{data.img_upload_progress + "%"}</strong></div>
          </div>
        </div>
      </div>
  
      <div className="col-md-6">
        <div className="form-group">
          <label className="require" for="input-required">Title En</label>
          <input className="form-control" type="text" 
            onChange={(event) => {
            data.title_en = event.target.value;
            this.setState({})
          }} 
          value={data.title_en}
          />
        </div>
        <div className="form-group">
            <label className="require" for="input-required">Description En</label>
            <textarea type="text" className="form-control" id="input-required" 
                onChange={(event) => {
                data.description_en = event.target.value;
                this.setState({})
              }} 
              value={data.description_en} 
            />
        </div> 
        <div className="form-group">
          <label className="require" for="input-required">Discount</label>
          <small className="sidetitle">E.g. $ 999.99</small>
          <input type="number" className="form-control" 
              onChange={(event) => {
              data.discount = event.target.value;
              this.setState({})
            }} 
            value={data.discount}
          />
        </div>
        <div className="form-group">
          <label>Number view</label>
          <input className="form-control" type="number"  
            onChange={(event) => {
              data.nb_views = event.target.value;
              this.setState({})}
            }
            value={data.nb_views} 
          />
        </div>
        <br />
      </div>
      <div className="card-body row">
        <div className="col-12">
            <div className="form-group">
              <h6 className="mb-1">
                <label className="require" for="input-required">Episode</label>
              </h6>
            </div>
        </div>
        

       { data.episode &&
        data.episode.map(eps => {
          return  <div className="col-md-4">
          <div className="form-group">
              <strong>{eps.resolution}</strong>
              {
                
                <div class="card">
                    <video ref={ref => eps.ref = ref}  controls style={{width: '100%'}}> 
                      <source src={eps.video_url} type="video/mp4"></source>
                    </video>
                </div>
              }
              <input 
              // accept="video/mp4"
              onChange={(e) => this.onChangeFile(e, eps)}
              type="file" data-provide="dropify" />
              <div class="progress">
              <div class="progress-bar" role="progressbar" style={{width: eps.upload_progress + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <strong>{eps.upload_progress || 0} %</strong>
              </div>
                  {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" 
                    style={{width: eps.upload_progress + "%"}}
                    ></div> */}
              </div>
          </div>
        </div>
        })
       }




      </div>
    </div>
    {/* custom modal */}
    <div className="modal modal-center fade" id="modal-center" tabindex="-1">
        <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title">Do you really want to delete this episode?</h5>
            <button type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
            <h6> 
              {/* Do you really want to delete this episode? */}
            </h6>
            <img className='center' src={deleteGif} width="500" height="350" />
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-bold btn-pure btn-secondary" data-dismiss="modal" onClick={() => data = null}>Close</button>
            <button type="button" className="btn btn-bold btn-pure btn-primary" data-dismiss="modal" onClick={() => this.onRemoveChild(data)}>
              Save changes</button>
            </div>
        </div>
        </div>
    </div>


    <footer className="card-footer ">
      <div className="text-right">
        <button className="btn btn-danger" data-toggle="modal" data-target="#modal-center" >Remove</button>
        {/* <button className="btn btn-danger" data-toggle="modal" data-target="#modal-center" onClick={() => this.onRemoveChild(data)} >Remove</button> */}
        <button className="btn btn-primary" onClick={() => this.onEpisodeSave(data)}>Save</button>
      </div>
    </footer>
  </div>
  }

  onRemoveChild(data){
    this.state.all_eposides.splice(this.state.all_eposides.indexOf(data), 1);
    toast.success("Your action is successful", {
      data: {
        title: "Success toast",
        text: "This is a success message",
      },
    })
    this.setState({})

  }

  onEpisodeSave=  async (data) => {

    let updatedEpisodes = [];

    data.episode.forEach(e => {
      if (e.filename) {
        updatedEpisodes.push({
          resolution: e.resolution,
          video_url: e.filename
        })
      }
    });

    let postData = {
      id: data.id,
      video_id: this.state.video_id,
      title_kh: data.title_kh,
      title_en: data.title_en,
      title_ch: data.title_ch,
      description_kh: data.description_kh,
      description_en: data.description_en,
      description_ch: data.description_ch,
      thumbnail_url: data.filename,
      nb_views: data.nb_views,
      price: data.price,
      discount: data.discount,
      release_date: data.release_date,
      episode: JSON.stringify(updatedEpisodes)
    };

    try {
      let results = await API.postEpisode(postData);
      
      if (results.result === 1){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
        data.id = results.data.episode_id
        if (this.state.all_eposides.length > this.state.limit_publish) {
          this.state.publish = false
        }
      }

    } catch (error) {
      console.log(error)
    }
    
    //  api.createvideo(data)
  }

  onThumbnailChange = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      let results = await API.adminUpload(
        formData,
        (percentage) => {
          this.state.video.preview_thumbnail_progress = percentage;
          this.setState({})
              
          // this.setState({percentage: percentage})
        }
      );
      
      if (results){
        this.state.video.thumbnail_url= results.full_url;
        this.state.video.filename= results.filename
        this.setState({})
      }

    } catch (error) {
      console.log(error)
    }
  }

  onPreviewVideo = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    
    try {
      let results = await API.adminUpload(
        formData,
        (percentage) => {
          this.state.video.preview_video_progress = percentage;
          this.setState({})
              
          // this.setState({percentage: percentage})
        }
      );
      
      if (results){
        this.state.video.preview_video_url= results.filename
        this.setState({})
      }

    } catch (error) {
      console.log(error)
    }
  }

  // upload episode video
  onChangeFile = (event, espisode) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    espisode.video_url =  URL.createObjectURL(file);
    this.setState({})
    
    try {
      API.adminUpload(
        formData,
        (percentage) => {
          espisode.upload_progress = percentage;
          this.setState({})
        }
      ).then(result => {
        espisode.upload_progress = 100;
        espisode.video_url = result.full_url;
        espisode.filename =result.filename;
        if (espisode.ref) {
          espisode.ref.load()
        }

        this.setState({})
      }).catch( error => {
        console.log(error)
      }) 
    } catch (error) {
      console.log(error)
    }
  }

  onImageChange = (event, episode) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    this.setState({})
    
    API.adminUpload(
      formData,
      (percentage) => {
        episode.img_upload_progress = percentage;
            
        this.setState({})
      }
    ).then(result => {
      if (result){
        episode.thumbnail_url = result.full_url;
        episode.filename = result.filename;
    
      }
    }).catch( error => {
      console.log(error)
    })
    
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    
    let data = {
      id: this.state.video.id,
      title_kh: this.state.video.title_kh,
      title_en: this.state.video.title_en,
      title_ch: "",
      description_kh: this.state.video.description_kh,
      description_en: this.state.video.description_en,
      description_ch: this.state.video.description_ch,
      thumbnail_url: this.state.video.filename ? this.state.video.filename : this.state.video.thumbnail_url,
      
      // preview_video_url: this.state.video.preview_video_url,
      total_price: this.state.video.total_price,
      discount: this.state.video.discount,
      release_date: this.state.video.release_date,
      // total_rating: this.state.video.total_rating,
      rating: this.state.video.rating,


      videos_category_id: JSON.stringify(this.state.isMulti? this.state.selected_tag.map(tag => {
        return {
          category_id: tag.id,
          video_id: this.state.video_id
        }
      }) : 
      [{
          category_id: this.state.selected_tag.id,
          video_id: this.state.video_id
      }]
    ),
    };

    if (data.thumbnail_url.includes("http")) {
      delete data.thumbnail_url;
    }

    try {
      let results = await API.postVideo(data);
      
      if (results.result){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
        this.state.video_id = results.data.video_id;
        this.setState({})
        // this.setState({ success: results.result })
      }

    } catch (error) {
      console.log(error)
    }
  }

  publishVideo = async (event) => {
    event.preventDefault();
    
    let data = {
      video_id: this.state.video_id
    };

    try {
      let results = await API.publishVideo(data);
      
      if (results.result){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
        this.state.success = true;
        this.setState({})
      }

    } catch (error) {
      console.log(error)
    }
  }

  videoInformationView() {
    return <form className="card" onSubmit={(e) => this.handleSubmit(e)}>
    <h4 className="card-title"><strong>Video Information</strong></h4>
    <div className="card-title row">
      <div className="col-md-6">
        <div className="form-group">
            <label className="require" for="input-required">Title Kh</label>
            <input type="text" className="form-control" id="input-required" name="title_kh"  required
            // validation={{
            //   required: {
            //     value: true,
            //     message: 'required',
            //   },
            //   minLength: {
            //     value: 6,
            //     message: 'min 6 characters',
            //   },
            // }}
            value={this.state.video.title_kh} 
             onChange={(event) => {
              this.state.video.title_kh = event.target.value;
              this.setState({})
             }}/>
        </div>
        <div className="form-group">
            <label className="require" for="input-required">Description Kh</label>
            <textarea type="text" className="form-control" id="input-required" name="description_kh" required
              value={this.state.video.description_kh}
              onChange={(event) => {
                this.state.video.description_kh = event.target.value;
                this.setState({})
               }}/>
        </div>
        
        <div className="form-group">
          <label className="require" for="input-required"> Total price</label>
          <small className="sidetitle">E.g. $ 999.99</small>
          <input type="number" className="form-control" name="total_price" required
            value={this.state.video.total_price}  
            onChange={(event) => {
              this.state.video.total_price = event.target.value;
              this.setState({})}
            }/>
        </div>
        
        <div className="form-group">
          <label className="require" for="input-required">Release_date</label>
          <input className="form-control" type="datetime-local" name="release_date" required
            value={this.state.video.release_date}  
            onChange={(event) => {
              this.state.video.release_date = event.target.value;
              this.setState({})}
            }
          />
        </div>

        <div className="form-group">
          <label> Total rating</label>
          
          <input type="number" className="form-control" name="total_rating" required
            value={this.state.video.total_rating} 
            onChange={(event) => {
              this.state.video.total_rating = event.target.value;
              this.setState({})}
            }/>
        </div>

        <div className="form-group">
          <label className="require" for="input-required">Thumbnail</label>
          <img 
             className={this.state.video.thumbnail_url? "h-300px w-fullscreen" : ""} 
             src={this.state.video.thumbnail_url} 
              /> 
            
          <input type="file" data-provide="dropify" name='thumbnail_url' onChange={e => this.onThumbnailChange(e)}  />
            <div class="progress">
              <div class="progress-bar" role="progressbar" style={{width: this.state.video.preview_thumbnail_progress + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <strong>{this.state.video.preview_thumbnail_progress + "%"}</strong>
              </div>
              {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" 
              style={{width: this.state.video.preview_thumbnail_progress + "%"}}></div> */}
            </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label className="require" for="input-required">Title En</label>
          <input className="form-control" type="text" name="title_en" required
            value={this.state.video.title_en}
            onChange={(event) => {
              this.state.video.title_en = event.target.value;
              this.setState({})}
            }/>
        </div>
        
        <div className="form-group">
            <label className="require" for="input-required">Description En</label>
            <textarea type="text" className="form-control" id="input-required" name="description_en" required
              value={this.state.video.description_en}  
              onChange={(event) => {
                this.state.video.description_en = event.target.value;
                this.setState({})}
              } />
        </div>
        
        <div className="form-group">
          <label className="require" for="input-required">Discount</label>
          <small className="sidetitle">E.g. %25.46</small>
          <input type="number" className="form-control"  name="discount" required
            value={this.state.video.discount}  
            onChange={(event) => {
              this.state.video.discount = event.target.value;
              this.setState({})}
            }/>
        </div>
        
        <div className="form-group">
          {/* <label>Tag</label> */}
          <label className="require" for="input-required">Category</label>
            <Select
            required
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 }),
              control: (base, state) => ({
                ...base,
                '&:hover': { borderColor: '#4ed2c5' },
                // '&:focus': {
                //   borderColor: '1px solid #4ed2c5',
                //   // boxShadow: '0px 0px 6px #4ed2c5',
                // },
                // border: '1px solid #4ed2c5',
                borderColor: state.isFocused ? '#4ed2c5' : '#ebebeb',
                boxShadow: 'none',
            }),
            }}
            value={this.state.selected_tag}
            isMulti={this.state.isMulti}

            name="options"
            options={this.state.all_tags}
            className="basic-multi-select"
            classNamePrefix="select"
            
            onChange={value => {
              this.setState({selected_tag: value})
              
            }}
            // onClick={event => event.stopPropagation()}
          />
        </div>
        
        <div className="form-group">
          <label>Rating</label>
          <input className="form-control" type="number" name="rating" required
            value={this.state.video.rating}  
            onChange={(event) => {
              this.state.video.rating = event.target.value;
              this.setState({})}
            }/>
        </div>
        {/* <div className="form-group">
          <label>Preview video</label>
          <input 
              accept="video/mp4"
              onChange={(e) => this.onPreviewVideo(e)}
              type="file" data-provide="dropify" />
              <div class="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" 
                    style={{width: this.state.video.preview_video_progress + "%"}}
                    ></div>
              </div>
        </div> */}
        <br />
      </div>
    </div>
    
    <footer className="card-footer ">
      <div className="text-right">
        {/* <Link to="/videoindex" className="btn btn-secondary" >Cancel</Link> */}
        {/* <button className="btn btn-primary" disabled={false} onClick={(e) => this.handleSubmit(e)}>Save</button> */}
        <button className="btn btn-primary" disabled={false} type="submit">Save</button>
      </div>
    </footer>
  </form>
  }
  

  render() {
    return (
        <div className="col-12">
          <ToastContainer />
          <div className="float_plus_button">
            <Link to="#" className="btn btn-float btn-primary" onClick={this.onAddChild}>  <i className="ion-plus-round"></i></Link>
          </div>
          <div className="float_top">
            <Link to="#" className="btn btn-float btn-info" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>  <i className="fa fa-level-up"></i></Link>
          </div>
          {this.state.success && (
              <Navigate to="/videoindex?success=true" replace={true} />
          )}

          {this.videoInformationView()}
            
          { this.state.all_eposides.map(data => {
            return this.episodeView(data);
          })}

          <footer className="card-footer text-right">
            <div className="card-body row">
            <div className="col-md-6">
                <div className="text-left">
                  {/* <Link to="#" className="btn btn-danger" onClick={this.onRemoveChild}> Remove <i className="ion-minus-round"></i></Link> */}

                  {/* <Link to="#" className="btn btn-info" onClick={this.onAddChild}> Add <i className="ion-plus-round"></i></Link> */}
                  <Link to="/videoindex" className="btn btn-secondary" >Back</Link>
                    {/* <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}>Submit</button> */}
                  <button className="btn btn-success" disabled={this.state.publish} onClick={(e) => this.publishVideo(e)}>Publish</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                    {/* <Link to="/videoindex" className="btn btn-secondary" >Back</Link> */}
                    {/* <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}>Submit</button> */}
                    {/* <button className="btn btn-success">Publish</button> */}
                </div>
              </div>
            </div> 
        </footer>
       </div>
    );
  }
  
  onAddChild = () => {

    let data = {
      "video_id" : this.state.id,
      "name_kh": "",
      "name_en": "",
      "name_ch": "",
      "description_kh": "",
      "description_en": "",
      "description_ch": "",
      "thumbnail_url": "",
      "img_upload_progress": 0, 
      "video_url": "",
      ref: null,
      episode : [
        {
          resolution: "1080p",
          upload_progress: 0,
          video_url: "",
        },
        {
          resolution: "720p",
          upload_progress: 0,
          video_url: "",
        },
        {
          resolution: "480p",
          upload_progress: 0,
          video_url: "",
        }
      ]
    };

    this.state.all_eposides.push(data);

    setTimeout(() => {
      if (this.state.all_eposides.length !== 1) {
        window.scrollTo({
          bottom: 2800,
          behavior: "smooth"
        })
        // toast.success("Your action is successful", {
        //   data: {
        //     title: "Success toast",
        //     text: "This is a success message",
        //   },
        // })
        // data.ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        data.ref.focus();
        
        
        // data.ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 300);

    

    this.setState({})
  }
}
  

export default VideoEdit;
