import React, {Component} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import API from "../../config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
};

class AboutUs extends Component {

  state = {
    id: 2,
    name: '',
    description: ''
  }

  UNSAFE_componentWillMount() {
    this.getAbout();
  }

  getAbout = async () => {
    try {
      let results = await API.getAbout("");
      let data = results.data;
      
      this.setState({ id : data.id, name: data.name, description: data.description });

    } catch (error) {
      console.log(error)
    }
  }

  handleChange = (value) => {
      this.setState({ description: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let results = await API.updateSetting({
        "name": this.state.name,
        "description": this.state.description
      });
      
      if (results.result === 1){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
      }
      console.log(results);

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
        <div className="col-12">
            <ToastContainer />
            <form className="card" onSubmit={this.handleSubmit}>
              <h4 className="card-title"><strong>About Us</strong></h4>

              <div className="card-body row">

                

                 <div className="card col-12">
                    {/* <h4 className="card-title"><strong>Description</strong></h4> */} 
                    <div className="card-body" >
                      <ReactQuill 
                        theme="snow" 
                        modules={modules}
                        style={{"height" : "300px"}} 
                        value={this.state.description} 
                        onChange={this.handleChange} />
                    </div>
                </div>
              </div>

              <footer className="card-footer text-right">
                <button className="btn btn-secondary" type="reset">Cancel</button>
                <button className="btn btn-primary" type="submit">Submit</button>
              </footer>
            </form>
          </div>
    );
  }
}



export default AboutUs;

// AboutUs.modules = {
//   toolbar: {
//     container: "#toolbar",
//   }
// };
// AboutUs.formats = [
//   'header', 'font', 'size',
//   'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'list', 'bullet', 'indent',
//   'link', 'image', 'video'
// ];
