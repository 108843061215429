import React, {Component} from "react";

class Error404 extends Component {

  render() {
    return (
        <div class="row no-margin h-fullscreen" style={{paddingTop: "10%"}}>

            <div class="col-12">
                <div class="card card-transparent mx-auto text-center">
                <h1 class="text-secondary lh-1" style={{fontSize: "200px"}}>404</h1>
                <hr class="w-30px" />
                <h3 class="text-uppercase">Page not found!</h3>

                <p class="lead">Seems you're looking for something that doesn't exist.</p>

                <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center fs-14">
                    <li class="nav-item">
                    <a class="nav-link" href="/">Report problem</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="/">Return home</a>
                    </li>
                </ul>
                <hr class="w-30px" />
                <form class="lookup lookup-lg w-250px mx-auto">
                    <input type="text" placeholder="Search..." />
                </form>
                </div>
            </div>


            <footer class="col-12 align-self-end text-center fs-13">
                <p>Copyright © 2019 <a href="http://thetheme.io/theadmin">TheAdmin</a>. All rights reserved.</p>
            </footer>
    </div>
    );    
  }
}

export default Error404;
