import React, {Component} from "react";
import { Link, Navigate } from "react-router-dom";
import Select from 'react-select';
import API from "../../config/api";

class VideoNew extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
        title_kh : "",
        title_en : "",
        title_ch : "",
        description_kh: "",
        description_en: "",
        description_ch: "",
        release_date: "",
        total_price: "",
        discount: "",
        thumbnail_url : "",
        preview_video_url: "",
        rating: "",
        total_rating: "",
        options: [],

        selected_tag_id: [],
        
        success: null, 
        error: null,
        tags: [],
        percentage: 0,
        disable: true,
      
       imageUrl: 'https://img.freepik.com/free-photo/side-view-ofserious-man_23-2148946213.jpg?size=626&ext=jpg&ga=GA1.1.1518270500.1717372800&semt=ais_user',
      //  options : [
      //   { value: 'chocolate', label: 'Chocolate' },
      //   { value: 'strawberry', label: 'Strawberry' },
      //   { value: 'vanilla', label: 'Vanilla' }
      // ],
      numChildren: 0
    }
  }

  UNSAFE_componentWillMount() {
    this.getAllCategories();
  }

  getAllCategories = async () => {
    try {
      let query = `page=${1}`
      let results = await API.getAllCategory(query);
      let data = results.data;
      
      this.setState({ tags : data.videos });

    } catch (error) {
      console.log(error)
    }
  }

  onChangeFile = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    
    try {
      let results = await API.adminUpload(
        formData,
        this.onUploadProgress
      );
      console.log(results)
      
      if (results){
        this.setState({ thumbnail_url: results.filename })
      }

    } catch (error) {
      console.log(error)
    }
  }

  handelInput = (event) => {
    event.preventDefault();
    let value = event.target.value;
    let name = event.target.name;

    console.log(name , value)
    
    this.setState({[name] : value})
  }

  onUploadProgress = (percentage) => {
    this.setState({ percentage: percentage})
    if (percentage ===100) {
      this.setState({ disable: false })
    }
  }

  onChangeFile = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    
    try {
      let results = await API.adminUpload(
        formData,
        this.onUploadProgress
      );
      
      if (results){
        this.setState({ thumbnail_url: results.filename })
      }

    } catch (error) {
      console.log(error)
    }
  }

  // handleChange = (selected: string[]) => {
  //   console.log(selected);
  // };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state.title_en,this.state.title_kh, this.state.description_kh,this.state.description_en,this.state.total_price,this.state.discount,
      this.state.release_date,this.state.rating,this.state.total_rating,this.state.thumbnail_url,"fdsajdfusuds8uusdjjsjjsjsjj"
    )
    let data = {
      "name_kh": this.state.title_kh,
      "name_en": this.state.title_en,
      "name_ch": "",
      "description_kh": this.state.description_kh,
      "description_en": this.state.description_en,
      "description_ch": this.state.description_ch,
      "total_price": this.state.total_price,
      "discount": this.state.discount,
      "option": this.state.options,
      "release_date": this.state.release_date,
      "rating": this.state.rating,
      "total_rating": this.state.total_rating,
      "thumbnail_url": this.state.thumbnail_url,
      "tags": JSON.stringify(this.state.selected_tag_id),
      // "tags": this.state.selected_tag_id
    };

    console.log(data)
    // try {
    //   let results = await API.postVideo([{
    //     "name_kh": this.state.title_kh,
    //     "name_en": this.state.title_en,
    //     "name_ch": "",
    //     "description_kh": this.state.description_kh,
    //     "description_en": this.state.description_en,
    //     "description_ch": this.state.description_ch,
    //     "total_price": this.state.total_price,
    //     "discount": this.state.discount,
    //     "release_date": this.state.release_date,
    //     "rating": this.state.rating,
    //     "total_rating": this.state.total_rating,
    //     "thumbnail_url": this.state.thumbnail_url
    //   }]);
      
    //   if (results.result === 1){
    //     this.setState({ success: results.result })
    //   }
    //   console.log(results);

    // } catch (error) {
    //   console.log(error)
    // }
  }

  render() {
    const children = [];
    for (var i = 0; i < this.state.numChildren; i += 1) {
      children.push(<ChildComponent key={i} number={i} />);
    };
    const tags_options = [];
    this.state.tags.map((item) => (
      tags_options.push({
        value: item,
        label: item.name_en
        })
    ))
    


    return (
        <div className="col-12">
          {this.state.error && <p>{this.state.error.message}</p>}
            {this.state.success && (
              <Navigate to="/videoindex" replace={true} />
            )}
            
            {/* <div class="card card-inverse bg-img" style={{backgroundImage: `url(${this.state.imageUrl})`}} data-overlay="2">
              <div class="flexbox px-20 pt-20">
                <label class="toggler toggler-danger text-white">
                  <input type="checkbox" />
                  <i class="fa fa-heart"></i>
                </label>
                <div class="dropdown">
                  <Link data-toggle="dropdown" to="#"><i class="ti-more-alt rotate-90 text-white"></i></Link>
                  <div class="dropdown-menu dropdown-menu-right">
                    <Link class="dropdown-item" to="#"><i class="fa fa-user"></i> Profile</Link>
                    <Link class="dropdown-item" to="#"><i class="fa fa-picture-o"></i> Shots</Link>
                    <Link class="dropdown-item" to="#"><i class="ti-check"></i> Follow</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" to="#"><i class="fa fa-ban"></i> Block</Link>
                  </div>
                </div>
              </div>

              

              <ul class="card-body flexbox flex-justified text-center" data-overlay="4">
                <li>
                  <span class="opacity-60">Love</span><br />
                  <span class="fs-22">3.6K</span>
                </li>
                <li>
                  <span class="opacity-60">Following</span><br />
                  <span class="fs-22">426</span>
                </li>
                <li>
                  <span class="opacity-60">Tweets</span><br />
                  <span class="fs-22">165</span>
                </li>
              </ul>
            </div> */}
            <form className="card" onSubmit={this.handleSubmit}>
              <h4 className="card-title"><strong>Video Information</strong></h4>
              <div className="card-title row">
                <div className="col-md-6">
                  <div className="form-group">
                      <label className="require" for="input-required">Title Kh</label>
                      <input type="text" className="form-control" id="input-required" name="title_kh" onChange={this.handelInput} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description Kh</label>
                      <textarea type="text" className="form-control" id="input-required" name="description_kh" onChange={this.handelInput} />
                  </div>
                  
                  <div className="form-group">
                    <label> Totla price</label>
                    <small className="sidetitle">E.g. $ 999.99</small>
                    <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" name="total_price" onChange={this.handelInput} />
                  </div>
                  <div className="form-group">
                    <label>Release_date</label>
                    <input className="form-control" type="datetime-local" name="release_date" onChange={this.handelInput} />
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title En</label>
                    <input className="form-control" type="text" name="title_en" onChange={this.handelInput} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description En</label>
                      <textarea type="text" className="form-control" id="input-required" name="description_en" onChange={this.handelInput} />
                  </div>

                  
                  <div className="form-group">
                    <label>Discount</label>
                    <small className="sidetitle">E.g. %25.46</small>
                    <input type="text" className="form-control" data-format="%{{99}}.{{99}}" />
                  </div>

                  <div className="form-group">
                    {/* <label for="inputState" className="col-form-label">Tag</label> */}
                    <label>Tag</label>
                      <Select
                        // defaultValue={[tags_options[2], tags_options[1]]}
                        closeMenuOnSelect={false}
                        isMulti
                        name="options"
                        options={tags_options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value) => {
                          console.log(value)
                          this.state.selected_tag_id = value.map(v => {
                            return v.value.id
                          })
                        } }
                      />
                  </div>
                  <br />
                </div>
              </div>
              <footer className="card-footer ">
            {/* <div className="card-body row"> */}
              {/* <div className="col-md-6">
                  <div className="text-left">
                      <Link to="#" className="btn btn-danger" onClick={this.onRemoveChild}> Remove <i className="ion-minus-round"></i></Link>
                      <Link to="#" className="btn btn-info" onClick={this.onAddChild}> Add <i className="ion-plus-round"></i></Link>
                  </div>
                </div> */}
                {/* <div className="col-md-6"> */}
                  <div className="text-right">
                      <Link to="/videoindex" className="btn btn-secondary" >Cancel</Link>
                      <button className="btn btn-primary" type="submit" disabled={false}>Save</button>
                  </div>
                {/* </div> */}
            {/* </div>     */}
            </footer>
            </form>
            <form className="card">
              <div className="card-body row">
                <div className="col-md-6">
                  <div className="form-group">
                      <label className="require" for="input-required">Title en</label>
                      <input type="text" className="form-control" id="input-required" name="title_en" onChange={this.handelInput} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description en</label>
                      <textarea type="text" className="form-control" id="input-required" name="description_en" onChange={this.handelInput} />
                  </div>
                  
                  <div className="form-group">
                    <label>Price for each episode</label>
                    <small className="sidetitle">E.g. $ 999.99</small>
                    <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" name="price" onChange={this.handelInput} />
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title En</label>
                    <input className="form-control" type="text" name="title_en" onChange={this.handelInput} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description En</label>
                      <textarea type="text" className="form-control" id="input-required" name="description_en" onChange={this.handelInput} />
                  </div> 
                  <div className="form-group">
                    <label>Thumbnail</label>
                    <div class="input-group file-group">
                      <input type="text" class="form-control file-value" placeholder="Choose file..." readonly />
                      <input type="file" multiple onChange={this.onChangeFile} />
                      <span class="input-group-append">
                        <button class="btn btn-light file-browser" type="button"><i class="fa fa-upload"></i></button>
                      </span>
                    </div>
                  </div>
                </div>


                {/* <div className="col-12">
                  <div className="col-md-8">
                      <div className="form-group">
                          <label>Video 720</label>
                          <input type="file" data-provide="dropify" />
                         
                      </div>
                    </div>
                </div> */}


                <div className="card-body row">
                  <div className="col-12">
                      <div className="form-group">
                        <h6 className="mb-1">Episode</h6>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                        <label>Video 720</label>
                        <input type="file" data-provide="dropify" onChange={this.onChangeFile} />
                        <div class="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div>
                        </div>
                        {/* <div style={{textAlign:'center'}}> 
                          <input type="text" value="100" data-provide="knob"></input>
                        </div> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                        <label>Video 1080</label>
                        <input type="file" data-provide="dropify" onChange={this.onChangeFile} />
                        <div class="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div>
                        </div>
                        {/* <div style={{textAlign:'center'}}> 
                          <input type="text" value="50" data-provide="knob"></input>
                        </div> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                        <label>Video 4k</label>
                        <input type="file" data-provide="dropify" onChange={this.onChangeFile} />
                        <div class="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div>
                        </div>
                        {/* <div style={{textAlign:'center'}}> 
                          <input type="text" value="2" data-provide="knob" data-readonly="true"></input>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {children}
            <footer className="card-footer ">
            <div className="card-body row">
              <div className="col-md-6">
                  <div className="text-left">
                      <Link to="#" className="btn btn-danger" onClick={this.onRemoveChild}> Remove <i className="ion-minus-round"></i></Link>
                      <Link to="#" className="btn btn-info" onClick={this.onAddChild}> Add <i className="ion-plus-round"></i></Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-right">
                      <Link to="/videoindex" className="btn btn-secondary" >Cancel</Link>
                      <button className="btn btn-primary" type="submit" disabled={false}>Submit</button>
                  </div>
                </div>
            </div>    
            </footer>
            {/* <footer className="card-footer text-left">
                 <Link to="/videoindex"><button className="btn btn-secondary" type="reset">Cancel</button></Link>
                <Link to="#" className="btn btn-danger" onClick={this.onRemoveChild}> Remove <i className="ion-minus-round"></i></Link>
                <Link to="#" className="btn btn-info" onClick={this.onAddChild}> Add <i className="ion-plus-round"></i></Link>
                 <div onClick={this.onAddChild} className="btn btn-info"> Add <i className="ion-plus-round"></i></div> 
                <Link to="/videoindex" className="btn btn-secondary" >Cancel</Link>
                <button className="btn btn-primary" type="submit">Submit</button>
            </footer> */}
          </div>
          
    );
    
  }
  onAddChild = () => {
    this.setState({
      numChildren: this.state.numChildren + 1
    });
  }
  onRemoveChild = () => {
    if (this.state.numChildren > 0) {
      this.setState({
        numChildren: this.state.numChildren - 1
      });
    }
  }
}

const ChildComponent = props => 
<form className="card">
  <div className="card-body row">

    <div className="col-md-6">
      <div className="form-group">
          <label className="require" for="input-required">Title Kh</label>
          <input type="text" className="form-control" id="input-required" />
      </div>
      <div className="form-group">
          <label className="require" for="input-required">Description Kh</label>
          <textarea type="text" className="form-control" id="input-required" />
      </div>
      
      <div className="form-group">
        <label>Price for each episode</label>
        <small className="sidetitle">E.g. $ 999.99</small>
        <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" />
      </div>
      {/* <div className="form-group">
        <label>Promote Code</label>
        <input className="form-control" type="text" />
      </div> */}
      {/* <div className="form-group">
        <label className="require" for="input-required" > Episode </label>
        <input className="form-control" type="number" min="1" max="100" required />
      </div> */}
    </div>


    <div className="col-md-6">
      <div className="form-group">
        <label>Title En</label>
        <input className="form-control" type="text" />
      </div>
      <div className="form-group">
          <label className="require" for="input-required">Description En</label>
          <textarea type="text" className="form-control" id="input-required" />
      </div> 

      
      {/* <div className="form-group">
        <label>Discount</label>
        <small className="sidetitle">E.g. %25.46</small>
        <input type="text" className="form-control" data-format="%{{99}}.{{99}}" />
      </div> */}

      {/* <div className="form-group">
        <label for="inputState" className="col-form-label">Category</label>
        <select id="inputState" className="form-control">
          <option>Short Films</option>
          <option>Funny</option>
          <option>Love story</option>
          <option>Sad story</option>
        </select>
      </div> */}
      <div className="form-group">
        <label>Thumbnail</label>
        <div class="input-group file-group">
          <input type="text" class="form-control file-value" placeholder="Choose file..." readonly />
          <input type="file" multiple />
          <span class="input-group-append">
            <button class="btn btn-light file-browser" type="button"><i class="fa fa-upload"></i></button>
          </span>
        </div>
      </div>

      {/* <div class="custom-controls-stacked">
        <div class="custom-control no-border custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="ccb-3" checked disabled />
          <label class="custom-control-label" for="ccb-3">Disable and unchecked</label>
        </div>
      </div>   */}
      <br />

      {/* <div class="form-group custom-control custom-control-lg custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="ccs-3" />
        <label class="custom-control-label" for="ccs-3">Free</label>
      </div> */}
    </div>


    <div className="card-body row">
      <div className="col-12">
          <div className="form-group">
            <h6 className="mb-1">Episode</h6>
          </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
            <label>Video 720</label>
            <input type="file" data-provide="dropify" />
            <div class="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "0%"}}></div>
            </div>
            {/* <div class="progress">
                  <div class="progress-bar" role="progressbar" style={{width: "50%", height: "16px"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>50%</strong></div>
            </div> */}
            {/* <div style={{textAlign:'center'}}> 
              <input type="text" value="100" data-provide="knob"></input>
            </div> */}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
            <label>Video 1080</label>
            <input type="file" data-provide="dropify"/>
            <div class="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100" style={{width: "55%"}}></div>
            </div>
            {/* <div class="progress">
                  <div class="progress-bar" role="progressbar" style={{width: "50%", height: "16px"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>50%</strong></div>
            </div> */}
            {/* <div style={{textAlign:'center'}}> 
              <input type="text" value="50" data-provide="knob"></input>
            </div> */}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
            <label>Video 4k</label>
            <input type="file" data-provide="dropify" />
            <div class="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100" style={{width: "15%"}}></div>
            </div>
            {/* <div class="progress">
                  <div class="progress-bar" role="progressbar" style={{width: "50%", height: "16px"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>50%</strong></div>
            </div> */}
            {/* <div style={{textAlign:'center'}}> 
              <input type="text" value="2" data-provide="knob" data-readonly="true"></input>
            </div> */}
        </div>
      </div>
    </div>
  </div>
</form>

export default VideoNew;
