import React, {Component} from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import API from "../../config/api";
// import Roles from "../Bar/Roles";

class UserEdit extends Component {

  
  constructor(props) {
    super(props)
  
    this.state = {
      user_id : 1, 
      user: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.getOneUser();
  }

  getOneUser = async () => {
    try {
      let query = `user_id=${this.state.user_id}`
      let results = await API.getOneUser(query);

      let data = results.data.user;
      console.log(data)
      this.setState({ user : data });

    } catch (error) {
      console.log(error)
    }
  }
  
  handelInput = (event) => {
    event.preventDefault();
    let value = event.target.value;
    let name = event.target.name;
    
    this.setState({ user: {
      ...this.state.user,
      [name]: value
    }})
  }

  handelSubmit = (e) => {
    e.preventDefault();

    axios.put(this.state.getUserApi, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.user),
    })
      .then((data) => {
        navigator("/show-user");
      })
      .catch((error) => {
        console.log(error.message);
      })
  };

  render() {
    const bannerStyle = {
      backgroundImage: `(${this.state.user.cover_url ? this.state.user.cover_url : require("../../assets/assets_theme/img/avatar/1.jpg")})`,  
      // backgroundImage: `url(${this.state.user.cover_url ? `${this.state.user.cover_url}` : `url(${require("../../assets/assets_theme/img/avatar/1.jpg")})` })`,
      // backgroundPosition: "center",
      // backgroundSize: "cover"
  }
  console.log(bannerStyle, "zzzzzzz")

    return (
          <div className="col-12">
            <form className="card">
              <h4 className="card-title fw-400">User Information</h4>
              <br />

              {/* <header class="header bg-img" style={{backgroundImage: `url(${this.state.user.cover_url})`}}> */}
              {/* <header class="header bg-img" style={{  backgroundImage: `url(${require("../../assets/assets_theme/img/avatar/1.jpg")})` }}> */}
              <header class="header bg-img" style={bannerStyle}>
                <div class="header-info h-250px mb-0">
                  <div class="media align-items-end">
                    <img class="avatar avatar-xl avatar-bordered" src={this.state.user.profile_url && this.state.user.profile_url != ""  ? this.state.user.profile_url : require('../../assets/assets_theme/img/avatar/1.jpg')}  alt="..." />
                    <div class="media-body">
                      <p class="text-white opacity-90"><strong>{this.state.user.first_name} {this.state.user.last_name} {"(" + this.state.user.nick_name + ")"} </strong></p>
                      {/* <small class="text-white opacity-60">Web Designer</small> */}
                    </div>
                  </div>
                </div>
              </header>

              <div className="card-body">
                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Fist Name</label>
                        <input type="text" className="form-control" id="input-required" name="first_name" value={this.state.user.first_name} onChange={this.handelInput} />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Last Name</label>
                        <input type="text" className="form-control" id="input-required" name="last_name" value={this.state.user.last_name} onChange={this.handelInput} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Nick Name</label>
                        <input className="form-control" type="text" name="nick_name" value={this.state.user.nick_name} onChange={this.handelInput} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Email</label>
                        <input className="form-control" type="text" name="email" value={this.state.user.email} onChange={this.handelInput} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Point</label>
                        <input className="form-control" type="text" name="points" value={this.state.user.points} onChange={this.handelInput} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Bio</label>
                        <input className="form-control" type="text" name="bio" value={this.state.user.bio} onChange={this.handelInput} />
                    </div>
                </div>

                <div className="row">
                <div className="form-group col-md-6">
                    <label className="text-fader">Date of birth</label>
                    <input className="form-control" type="date" name="created_at" value={this.state.user.created_at} onChange={this.handelInput} />
                    {/* <input className="form-control" type="datetime-local" name="created_at" value={this.state.user.created_at} onChange={this.handelInput} /> */}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Phone Number 1</label>
                    <input className="form-control" type="text" name="phone_number" value={this.state.user.phone_number} onChange={this.handelInput} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Gender</label>
                    <select className="form-control" title="&#xA0;" name="gender_id" defaultValue={this.state.user.gender_id} onChange={this.handelInput} >
                      <option value={1}> Male</option>
                      <option value={2}>Female</option>
                      <option value={3}>Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Role</label>
                    <select className="form-control" title="&#xA0;" name="role_id" defaultValue={this.state.user.role_id} onChange={this.handelInput}>
                      <option value={1}> User</option>
                      <option value={2}>Editor</option>
                      <option value={3}>Admin</option>
                    </select>
                  </div>

                  

                  <div className="form-group col-md-6">
                    <label className="text-fader">Facebook ID</label>
                    <input className="form-control" type="text" name="fb_id" value={this.state.user.fb_id} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Apple ID</label>
                    <input className="form-control" type="text" name="apple_id" value={this.state.user.apple_id} />
                  </div>
                </div>

                <hr />
              </div>
              {/* <Roles /> */}
              {/* <div className="card-body">
                <button className="btn btn-block btn-round btn-bold btn-primary" type="submit">Save</button>
              </div> */}
              <footer class="card-footer text-right">
                {/* <button class="btn btn-secondary" type="reset">Cancel</button> */}
                <Link class="btn btn-secondary" to={'/userindex'} >Cancel</Link>
                <button class="btn btn-primary" type="submit" onSubmit={this.handelSubmit}>Submit</button>
              </footer>

            </form>
          </div>
    );
  }
}

export default UserEdit;
