import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class SellReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nb_days: 15,
      data: [],
    }
  }

  getRamdom() {
    return Math.floor(Math.random() * 1000) + 3000
  }

  getDay(i) {
    return moment().subtract(i, 'months').format("MMM YYYY")
  }

  getData() {
    let tempArray = []
    for (let i = this.state.nb_days; i > 0; i--) {
      tempArray.push({
        key: i,
        sell_per_month: this.getRamdom(),
        name: this.getDay(i)
      })
    }
    this.setState({data: tempArray})
  }

  componentWillMount() {
    this.getData();
  }

  render() {
    
    return (
      <div class="col-md-12">
            <div class="card">
              <div className='row'>
                <div class="col-md-6 col-xl-3">
                  <div class="card card-body">
                  <h6>
                  <span class="text-uppercase">Revenue</span>
                  <span class="float-right"><a class="btn btn-xs btn-primary" href="#">View</a></span>
                  </h6>
                  <br />
                  <p class="fs-28 fw-100">$21,642</p>

                    <div class="progress">
                      <div class="progress-bar bg-danger" role="progressbar" style={{width: "35%", height: "4px"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="text-gray fs-12"><i class="ti-stats-down text-success mr-1"></i> %18 decrease from last month</div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card card-body">
                    <h6>
                      <span class="text-uppercase">Sells</span>
                      <span class="float-right"><a class="btn btn-xs btn-primary" href="#">View</a></span>
                    </h6>
                    <br />
                    <p class="fs-28 fw-100">2,354</p>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: "65%", height: "4px"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="text-gray fs-12"><i class="ti-stats-up text-success mr-1"></i> 324 more than last year</div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card card-body">
                    <h6>
                      <span class="text-uppercase">Orders</span>
                      <span class="float-right"><a class="btn btn-xs btn-primary" href="#">View</a></span>
                    </h6>
                    <br />
                    <p class="fs-28 fw-100">653</p>
                    <div class="progress">
                      <div class="progress-bar bg-warning" role="progressbar" style={{width: "65%", height: "4px"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="text-gray fs-12"><i class="ti-arrow-down text-danger mr-1"></i> %32 down</div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card card-body">
                    <h6>
                      <span class="text-uppercase">Visitors</span>
                      <span class="float-right"><a class="btn btn-xs btn-primary" href="#">View</a></span>
                    </h6>
                    <br />
                    <p class="fs-28 fw-100">18,964</p>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" style={{width: "85%", height: "4px"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="flexbox text-gray fs-12">
                      <span><i class="ti-arrow-up text-success mr-1"></i> %25 up</span>
                      <span>+1000</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              {/* Sell per month */}
              <div className='card'>
                <div class="card-header">
                  <h5 class="card-title">Total sell of each month</h5>

                  <ul class="card-controls">
                    <li class="dropdown">
                      <a data-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item active" href="#">1 Year</a>
                        <a class="dropdown-item" href="#">2 Year</a>
                        <a class="dropdown-item" href="#">3 Year</a>
                        <a class="dropdown-item" href="#">4 Year</a>
                      </div>
                    </li>
                    {/* <li><a class="card-btn-reload" href="../assets/data/general.html" title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li> */}
                  </ul>
                </div>

                <div class="card-body card-body">
              {
                this.state.data.length != 0 && 
                <div  style={{width: '100%', height: 300 }}>
                <ResponsiveContainer debounce={300} width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={this.state.data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                  >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="sell_per_month" fill="#4ed2c5" background={{ fill: '#eee' }} />
                  </BarChart>
                </ResponsiveContainer>
                </div>
              }
                </div>
              </div>

              <div className='card'>
              <div class="card-header">
                <h5 class="card-title"><strong>Last Sales</strong></h5>
                <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
              {/* <Link class="btn btn-xs btn-light" to=''>See all</Link> */}
                

                <button className="btn btn-info dropdown-toggle" data-toggle="dropdown">Export</button>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link class="dropdown-item"  ><i class="fa fa-file-zip-o"></i> Zip</Link>
                    <Link class="dropdown-item"  ><i class="fa fa-file-pdf-o"></i> PDF</Link>
                    <Link class="dropdown-item"  ><i class="fa fa-file-excel-o"></i> Excel</Link>
                </div>
                <select data-provide="selectpicker" data-width="80">
                  <option>Today</option>
                  <option>Yesterday</option>
                  <option>Last week</option>
                  <option>Last month</option>
                </select>
                
              </div>
                
              </div>

              <div class="card-body bl-3 border-success flexbox flex-justified">
                <div>
                  <div class="fs-12 text-muted"><i class="ti-time mr-1"></i> 24 hours</div>
                  <div class="fs-18 text-success">26 Sales</div>
                </div>

                <div>
                  <div class="fs-12 text-muted"><i class="ti-time mr-1"></i> 7 days</div>
                  <div class="fs-18 text-danger">134 Sales</div>
                </div>

                <div class="fs-40 fw-100 text-right pr-2 text-success">$106</div>
              </div>

              <table class="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td><a class="hover-info" href="https://themeforest.net/item/thedocs-online-documentation-template/13070884">TheDocs - Online Documentation Template</a></td>
                    <td class="text-muted w-80px">21:53</td>
                    <td class="text-success fw-500 w-80px">+ $19</td>
                  </tr>

                  <tr>
                    <td><a class="hover-info" href="https://themeforest.net/item/thejobs-job-board-template/15383160">TheJobs - Job Board Template</a></td>
                    <td class="text-muted w-80px">20:15</td>
                    <td class="text-success fw-500 w-80px">+ $21</td>
                  </tr>

                  <tr>
                    <td><a class="hover-info" href="https://themeforest.net/item/theguide-online-documentation-template/14239305">TheGuide - Online Documentation Template</a></td>
                    <td class="text-muted w-80px">17:09</td>
                    <td class="text-success fw-500 w-80px">+ $18</td>
                  </tr>

                  <tr>
                    <td><a class="hover-info" href="https://themeforest.net/item/theshots-responsive-image-listing-template/14799485">TheShots - Responsive Image Listing Template</a></td>
                    <td class="text-muted w-80px">14:36</td>
                    <td class="text-success fw-500 w-80px">+ $16</td>
                  </tr>

                  <tr>
                    <td><a class="hover-info" href="https://themeforest.net/item/thedocs-online-documentation-template/13070884">TheDocs - Online Documentation Template</a></td>
                    <td class="text-muted w-80px">11:26</td>
                    <td class="text-success fw-500 w-80px">+ $19</td>
                  </tr>
                </tbody>
              </table>
              </div>

                    
              
          </div>
    )
  }
}

export default SellReport