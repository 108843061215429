import React, { Component } from 'react'

export class role extends Component {



  render() {

    const roles = ['Super Admin', 'Admin', 'Editor', 'Controller', 'User', 'Viewer'];
    const permissions = ['Permission 1', 'Permission 2', 'Permission 3', 'Permission 4', 'Permission 5', 'Permission 6'];

    return (
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title"><strong>Role</strong></h5>
            {/* <a class="btn btn-xs btn-light" href="#">See all</a> */}
          </div>

          <table class="table table-hover table-has-action">
            <thead>
              <tr>
              <th>#</th>
              {permissions.map((permission, index) => (
                <th>{permission}</th>
              ))};
              </tr>
              
            </thead>
            <tbody>
              
              {roles.map((role, index) => (
                <tr>
                  <td>{role}</td>
                  <td><input type='checkbox' /></td>
                  <td><input type='checkbox' /></td>
                  <td><input type='checkbox' /></td>
                  <td><input type='checkbox' /></td>
                  <td><input type='checkbox' /></td>
                  <td><input type='checkbox' /></td>
                </tr>
                
              ))};

            </tbody>
          </table>

        </div>
      </div>
    )
  }
}

export default role