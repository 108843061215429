import axios from "axios";

export function getRequest(url, query) {
    const items = JSON.parse(localStorage.getItem('login'));
    
    return axios.get(
        `${url}?${query}`, 
        // { headers: { token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjEsInBhc3N3b3JkX2xldmVsIjoxfSwiaWF0IjoxNzE5OTgxNjQ1fQ.6cZfF5rM-p5Dnh_V7IHf60YU1PHKx45-j6BXx_W6d7c" }}
        { headers: { token: items.data.token }}
    )
        .then((res) => res.data);
}

