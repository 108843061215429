import React, {Component} from "react";
import { Link, Navigate } from "react-router-dom";
import API from "../../config/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CategoryNew extends Component {

  state = {
    name_kh : "",
    name_en : "",
    name_ch : "",
    thumbnail_url : "",
    success: null, 
    error: null,
    percentage: 0,
    disable: true
  }

  handelInput = (event) => {
    event.preventDefault();
    let value = event.target.value;
    let name = event.target.name;
    
    this.setState({[name] : value})
  }

  onUploadProgress = (percentage) => {
    this.setState({ percentage: percentage})
    if (percentage ===100) {
      this.setState({ disable: false })
    }
  }

  onChangeFile = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    
    try {
      let results = await API.adminUpload(
        formData,
        this.onUploadProgress
      );
      console.log(results)
      
      if (results){
        this.setState({ thumbnail_url: results.filename })
      }

    } catch (error) {
      console.log(error)
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let results = await API.postOneCategory({
        "name_kh": this.state.name_kh,
        "name_en": this.state.name_en,
        "name_ch": "",
        "thumbnail_url": this.state.thumbnail_url
      });
      
      if (results.result === 1){
        toast.success("Your action is successful", {
          data: {
            title: "Success toast",
            text: "This is a success message",
          },
        })
        this.setState({ success: results.result })
      }

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
        <div className="col-12">
          <ToastContainer />
          {this.state.error && <p>{this.state.error.message}</p>}
            {this.state.success && (
              <Navigate to="/categoryindex?success=true" replace={true} />
            )}
            
            <form className="card" onSubmit={this.handleSubmit}>
              <h4 className="card-title"><strong>Category</strong></h4>

              <div className="card-body row">

                <div className="col-md-6">
                  <div className="form-group">
                      <label className="require" for="input-required">Name Kh</label>
                      <input type="text" className="form-control" id="input-required" name="name_kh" onChange={this.handelInput} />
                  </div>
                  {/* <div className="form-group">
                    <label>Description Kh</label>
                    <textarea className="form-control" type="text" name="description_kh" onChange={this.handelInput} />
                  </div> */}
                  {/* <div className="form-group">
                    <label for="inputState" className="col-form-label">Parent</label>
                    <select id="inputState" className="form-control">
                      <option>Short Film</option>
                      <option>Funny</option>
                      <option>Love story</option>
                      <option>Sad story</option>
                    </select>
                  </div> */}
                  
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                        <label className="require" for="input-required">Name En</label>
                        <input type="text" className="form-control" id="input-required" name="name_en" onChange={this.handelInput} />
                  </div>
                  {/* <div className="form-group">
                    <label>Description En</label>
                    <textarea className="form-control" type="text" name="description_en" onChange={this.handelInput} />
                  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Thumbnail</label>
                    <input type="file" data-provide="dropify" onChange={this.onChangeFile} />
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style={{width: this.state.percentage + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>{this.state.percentage + "%"}</strong></div>
                          {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div> */}
                    </div>
                  </div>
                </div>
              </div>
              <footer className="card-footer text-right">
                <Link className="btn btn-secondary" to="/categoryindex">Cancel</Link>
                <button className="btn btn-primary" type="submit" disabled={this.state.disable}>Submit</button>
              </footer>
            </form>
          </div>
    );
  }
}

export default CategoryNew;
