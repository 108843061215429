import React from 'react'
import { Link } from 'react-router-dom'

function VideoReport() {
  return (
    <div class="col-lg-12">
        <div class="card">
          <div className='row'>
            <div class="col-md-6 col-xl-3">
              <div class="card card-body bg-danger">
                <h6 class="text-uppercase text-white">Today likes</h6>
                <div class="flexbox mt-2">
                  <span class="fa fa-heart fs-30"></span>
                  <span class="fs-30">9,357</span>
                </div>
              </div>
            </div>
            
            <div class="col-md-6 col-xl-3">
              <div class="card card-body bg-purple">
                <h6 class="text-uppercase text-white">Last 7 days likes</h6>
                <div class="flexbox mt-2">
                  <span class="fa fa-heart fs-30"></span>
                  <span class="fs-30">9,357</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card card-body bg-info">
                <h6 class="text-uppercase text-white">Last month likes</h6>
                <div class="flexbox mt-2">
                  <span class="fa fa-heart fs-30"></span>
                  <span class="fs-30">9,357</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card card-body bg-success">
                <h6 class="text-uppercase text-white">Total likes</h6>
                <div class="flexbox mt-2">
                  <span class="fa fa-heart fs-30"></span>
                  <span class="fs-30">9,357</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="col-md-6 col-xl-3">
            <div class="card card-body">
              <h6>
                <span class="text-uppercase">Visitors</span>
                <span class="float-right"><a class="btn btn-xs btn-primary" href="#">View</a></span>
              </h6>
              <br />
              <p class="fs-28 fw-100">18,964</p>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" style={{width: "85%", height: "4px"}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="flexbox text-gray fs-12">
                <span><i class="ti-arrow-up text-success mr-1"></i> %25 up</span>
                <span>+1000</span>
              </div>
            </div>
          </div> */}

          <div class="card-header">
            <h5 class="card-title"><strong>Video</strong></h5>
            {/* <Link class="btn btn-xs btn-light" to=''>See all</Link> */}
            <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
              <Link class="btn btn-xs btn-light" to=''>See all</Link>
              <button className="btn btn-info dropdown-toggle" data-toggle="dropdown">Export</button>
              <div className="dropdown-menu dropdown-menu-right">
                  <Link class="dropdown-item"  ><i class="fa fa-file-zip-o"></i> Zip</Link>
                  <Link class="dropdown-item"  ><i class="fa fa-file-pdf-o"></i> PDF</Link>
                  <Link class="dropdown-item"  ><i class="fa fa-file-excel-o"></i> Excel</Link>
              </div>
            </div>
          </div>

          <table class="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Episode</th>
                <th>Total like</th>
                <th>Total View</th>
                <th>Release Date</th>
                <th class="w-80px text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Template PSD</td>
                <td>45</td>
                <td>4444</td>
                <td class="align-middle">
                  <div class="progress mb-0">
                    <div class="progress-bar" role="progressbar" style={{width: 95, height: 13}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"><b>5555</b></div>
                  </div>
                </td>
                <td>05 July 2024</td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Edit"><i class="ti-pencil"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-trash"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Wordpress boilerplate</td>
                <td>4444</td>
                <td>8555</td>
                <td class="align-middle">
                  <div class="progress mb-0">
                    <div class="progress-bar bg-success" role="progressbar" style={{width: 60, height: 13}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">4445</div>
                  </div>
                </td>
                <td>05 July 2024</td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Edit"><i class="ti-pencil"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-trash"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Multi purpuse HTML template</td>
                <td>4444</td>
                <td>2000</td>
                <td class="align-middle">
                  <div class="progress mb-0">
                    <div class="progress-bar bg-info" role="progressbar" style={{width: 45, height: 13}} aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">8888</div>
                  </div>
                </td>
                <td>05 July 2024</td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Edit"><i class="ti-pencil"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-trash"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Wordpress version of something</td>
                <td>4444</td>
                <td>3144</td>
                <td class="align-middle">
                  <div class="progress mb-0">
                    <div class="progress-bar bg-warning" role="progressbar" style={{width: 30, height: 13}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">554</div>
                  </div>
                </td>
                <td>05 July 2024</td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Edit"><i class="ti-pencil"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-trash"></i></a>
                  </nav>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>TheJobs wordpress version</td>
                <td>4444</td>
                <td>1700</td>
                <td class="align-middle">
                  <div class="progress mb-0">
                    <div class="progress-bar bg-danger" role="progressbar" style={{width: 15, height: 13}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">5</div>
                  </div>
                </td>
                <td>05 July 2024</td>
                <td>
                  <nav class="nav no-gutters gap-2 fs-16">
                    <a class="nav-link hover-primary" href="#" data-provide="tooltip" title="Edit"><i class="ti-pencil"></i></a>
                    <a class="nav-link hover-danger" href="#" data-provide="tooltip" title="Delete"><i class="ti-trash"></i></a>
                  </nav>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
  )
}

export default VideoReport