import React, { Component } from 'react'
import deleteGif from '../../../src/assets/assets_theme/img/trash-can.gif'

export class CustomModal extends Component {

// constructor(props) {
//   super(props);
//   this.state = {
//     data: this.props.data
//   }
// }

render() {
  let data = this.props.data;
  let title = this.props.title
  return (
    
    <div className="modal modal-center fade" id="modal-center" tabindex="-1">
        <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
            <h6> 
              { data.name_kh ? data.name_kh : ''} { data.name_en ? data.name_en : ''} 
              { data.title_kh ? data.title_kh : ''} { data.title_en ? data.title_en : ''}
              { data.first_name ? data.first_name : ''} { data.last_name ? data.last_name : ''} { data.nick_name ? data.nick_name : ''}
            </h6>
            <img className='center' src={deleteGif} width="500" height="350" />
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-bold btn-pure btn-secondary" data-dismiss="modal" onClick={() => data = null}>Close</button>
            <button type="button" className="btn btn-bold btn-pure btn-primary" data-dismiss="modal" onClick={() => {
              this.props.delete(data.id);

            }}>Save changes</button>
            </div>
        </div>
        </div>
    </div>
  )
}
}

export default CustomModal