import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import API from "../../config/api";

export class CategoryEdit extends Component {

    constructor(props) {
        super(props)
      
        this.state = {
          category_id: 1,
          categories: [],
          percentage: 0,
          disable: true,
          thumbnail: "",
          image_url: "",
          success: null
        }
      }

      UNSAFE_componentWillMount() {
        this.getParam("id")
        // console.log("id of catetory: ", this.getParam("id"))
        this.getOneCategory();
        this.getImage();
      }

      getParam(name) {
        let id = window.location.search.split(`${name}=`)[1]
        this.state.category_id = id;
        // this.setState({category_id : id})
        this.setState({})
        // return 
      }

      getImage() {
        let url = API.getImage();
        this.setState({image_url : url})
      }
    
      getOneCategory = async () => {
        try {
          let query = `category_id=${this.state.category_id}`
          let results = await API.getOneCategory(query);
    
          let data = results.data;
          console.log(data)
          this.setState({ 
            categories : data, 
            thumbnail: data.thumbnail_url
          });
    
        } catch (error) {
          console.log(error)
        }
      }

      onUploadProgress = (percentage) => {
        this.setState({ percentage: percentage})
        if (percentage ===100) {
          this.setState({ disable: false })
        }
      }

      onChangeFile = async (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        let name = event.target.name;
        const formData = new FormData();
        formData.append("file", file);
        
        try {
          let results = await API.adminUpload(
            formData,
            this.onUploadProgress
          );
          
          if (results){
            this.setState({ categories: {
              ...this.state.categories,
              [name]: results.filename,
            }})
          }
    
        } catch (error) {
          console.log(error)
        }
      }
      handelInput = (event) => {
        event.preventDefault();
        let value = event.target.value;
        let name = event.target.name;
        
        this.setState({ categories: {
          ...this.state.categories,
          [name]: value
        }})
      }
      
    
      handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
          let results = await API.postOneCategory(
            this.state.categories
          );
          if (results.result === 1){
            
            this.setState({ success: results.result })
          }
    
        } catch (error) {
          console.log(error)
        }
      }

  render() {
    return (
        <div className="col-12">
          {this.state.success && (
              <Navigate to="/categoryindex?success=true" replace={true} />
          )}
            <form className="card" onSubmit={this.handleSubmit}>
                <h4 className="card-title"><strong>Category</strong></h4>

                <div className="card-body row">

                    <div className="col-md-6">
                    <div className="form-group">
                        <label className="require" for="input-required">Name</label>
                        <input type="text" className="form-control" id="input-required" value={this.state.categories.name_kh} name='name_kh' onChange={this.handelInput} />
                    </div>
                    {/* <div className="form-group">  
                        <label for="inputState" className="col-form-label">Parent</label>
                        <select id="inputState" className="form-control">
                        <option>Short Film</option>
                        <option>Funny</option>
                        <option>Love story</option>
                        <option>Sad story</option>
                        </select>
                    </div> */}
                    
                    </div>


                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Description</label>
                        <input className="form-control" type="text" value={this.state.categories.name_en} name='name_en' onChange={this.handelInput} />
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Thumbnail</label>
                        <img className='h-300px w-fullscreen' src={this.state.thumbnail} alt="..." />
                        <br /><br />
                        <input type="file" data-provide="dropify" name='thumbnail_url' onChange={this.onChangeFile} />
                        <div class="progress">
                        < div class="progress-bar" role="progressbar" style={{width: this.state.percentage + "%", height: '10px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><strong>{this.state.percentage + "%"}</strong></div>
                          {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.state.percentage + "%"}}></div> */}
                        </div>
                    </div>
                    </div>
                    

                    
                </div>

                <footer className="card-footer text-right">
                    <Link className="btn btn-secondary" to="/categoryindex">Cancel</Link>
                    <button className="btn btn-primary" type="submit">Submit</button>
                </footer>
            </form>
        </div>
    )
  }
}

export default CategoryEdit