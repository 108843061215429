import React, {Component} from "react";
import { Link } from "react-router-dom";

class Register extends Component {

  render() {
    return (
        <div class="row no-gutters min-h-fullscreen bg-white">
      <div class="col-md-6 col-lg-7 col-xl-8 d-none d-md-block bg-img" style={{ backgroundImage: `url(${require("../assets/assets_theme/img/gallery/2.jpg")})` }} data-overlay="5">

        <div class="row h-100 pl-50">
          <div class="col-md-10 col-lg-8 align-self-end">
            <img src={require('../assets/assets_theme/img/logo-light-lg.png')} alt="..." />
            <br /><br /><br />
            <h4 class="text-white">The admin is the best admin framework available online.</h4>
            <p class="text-white">Credibly transition sticky users after backward-compatible web services. Compellingly strategize team building interfaces.</p>
            <br /><br />
          </div>
        </div>

      </div>



      <div class="col-md-6 col-lg-5 col-xl-4 align-self-center">
        <div class="px-80 py-30">
          <h4>Register an account</h4>
          <p><small>All fields are required.</small></p>
          <br />

          <form class="form-type-material">
            <div class="form-group">
              <input type="text" class="form-control" id="username" />
              <label for="username">Username</label>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" id="email" />
              <label for="email">Email address</label>
            </div>

            <div class="form-group">
              <input type="password" class="form-control" id="password" />
              <label for="password">Password</label>
            </div>

            <div class="form-group">
              <input type="password" class="form-control" id="password-conf" />
              <label for="password-conf">Password (confirm)</label>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" />
                <label class="custom-control-label">I agree to all <a class="text-primary" href="/">terms</a></label>
              </div>
            </div>

            <br />
            <button class="btn btn-bold btn-block btn-primary" type="submit">Register</button>
          </form>

          <hr class="w-30px" />

          <p class="text-center text-muted fs-13 mt-20">Already have an account? <Link class="text-primary fw-500" to="/login">Sign in</Link></p>
        </div>
      </div>
    </div>
    );    
  }
}

export default Register;
