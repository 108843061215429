import React, {Component} from "react";
import { Link } from "react-router-dom";
import API from "../../config/api";

class UserView extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       user_id : 1, 
       user: [],
    }
  }

  UNSAFE_componentWillMount() {
    this.getParam("id")
    this.getOneUser();
  }

  getParam(name) {
    let id = window.location.search.split(`${name}=`)[1]
    this.state.user_id = id;
    // this.setState({category_id : id})
    this.setState({})
    // return 
  }

  getOneUser = async () => {
    try {
      let query = `user_id=${this.state.user_id}`
      let results = await API.getOneUser(query);

      let data = results.data.user;
      console.log(data)
      this.setState({ user : data });

    } catch (error) {
      console.log(error)
    }
  }

  // componentDidMount() {
  //   axios.get(this.state.getUserApi)
  //     .then(res => {
  //       const user = res.data;
  //       this.setState({ user });
  //     }).catch(error => {
  //       console.log(error)

  //     }
  //     )
  // }

  render() {
    return (

          <div className="col-12">
            <form className="card">
              <h4 className="card-title fw-400"> User Information </h4>
              <br />

              <header class="header bg-img" >
              {/* <header class="header bg-img" style={{backgroundImage: `url(${this.state.user.cover_url})`}}> */}
                <div class="header-info h-250px mb-0">
                  <div class="media align-items-end">
                    <img class="avatar avatar-xl avatar-bordered" src={this.state.user.profile_url}  alt="..." />
                    <div class="media-body">
                      <p class="text-white opacity-90"><strong>{this.state.user.first_name} {this.state.user.last_name} {"(" + this.state.user.nick_name + ")"}</strong></p>
                      {/* <small class="text-white opacity-60">Web Designer</small> */}
                    </div>
                  </div>
                </div>
              </header>

              <div className="card-body">
                {/* <div className="flexbox gap-items-4">
                  <img className="avatar avatar-xl" src={this.state.user.profile_url} alt="..." />
                  <div className="flex-grow">
                    <h5>{this.state.user.nick_name}</h5>
                    <div className="d-flex flex-column flex-sm-row gap-items-2 gap-y mt-16">
                      <div className="file-group file-group-inline">
                        <button className="btn btn-sm btn-w-lg btn-outline btn-round btn-secondary file-browser" type="button">Change Picture</button>
                        <input type="file" />
                      </div>

                      <a className="btn btn-sm btn-w-lg btn-outline btn-round btn-danger" href="/">Delete Picture</a>
                    </div>

                  </div>
                </div> */}

                {/* <hr /> */}

                <div className="row">
                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Fist Name</label>
                        <input type="text" className="form-control" id="input-required" value={this.state.user.first_name} />
                    </div>

                    <div className="form-group col-md-6">
                        <label className="require" for="input-required">Last Name</label>
                        <input type="text" className="form-control" id="input-required" value={this.state.user.last_name} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Nick Name</label>
                        <input className="form-control" type="text" value={this.state.user.nick_name} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Email</label>
                        <input className="form-control" type="text" value={this.state.user.email} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Point</label>
                        <input className="form-control" type="text" value={this.state.user.points} />
                    </div>
                    <div className="form-group col-md-6">
                        <label className="text-fader">Bio</label>
                        <input className="form-control" type="text" value={this.state.user.bio} />
                    </div>
                </div>

                <div className="row">
                <div className="form-group col-md-6">
                    <label className="text-fader">Date of birth</label>
                    <input className="form-control" type="date" value={this.state.user.dob} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Phone Number 1</label>
                    <input className="form-control" type="text" value={this.state.user.phone_number} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Gender</label>
                    <select className="form-control" title="&#xA0;" disabled defaultValue={this.state.user.gender_id} >
                      <option value={1}> Male</option>
                      <option value={2}>Female</option>
                      <option value={3}>Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Role</label>
                    <select className="form-control" title="&#xA0;" disabled defaultValue={this.state.user.role_id}>
                      <option value={1}> User</option>
                      <option value={2}>Editor</option>
                      <option value={3}>Admin</option>
                    </select>
                  </div>

                  

                  <div className="form-group col-md-6">
                    <label className="text-fader">Facebook ID</label>
                    <input className="form-control" type="text" value={this.state.user.fb_id} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="text-fader">Apple ID</label>
                    <input className="form-control" type="text" value={this.state.user.apple_id} />
                  </div>
                </div>

                <hr />
              </div>
              {/* <Roles /> */}
              {/* <div className="card-body">
                <button className="btn btn-block btn-round btn-bold btn-primary" type="submit">Save</button>
              </div> */}
              <footer class="card-footer text-right">
                {/* <button class="btn btn-secondary" type="reset">Cancel</button> */}
                <Link class="btn btn-secondary" to={'/userindex'} >Back</Link>
                {/* <button class="btn btn-primary" type="submit">Submit</button> */}
              </footer>

            </form>
          </div>
    );
  }
}

export default UserView;
