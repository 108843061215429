import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';


class Topbar extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      login: false,
      imageUrl: 'https://img.freepik.com/free-photo/side-view-ofserious-man_23-2148946213.jpg?size=626&ext=jpg&ga=GA1.1.1518270500.1717372800&semt=ais_user'
    }
  }

  UNSAFE_componentWillMount() {
    this.testLogin();
  }

  logOut = () => {
    localStorage.removeItem('login')
  }

  testLogin = () => {
    const items = JSON.parse(localStorage.getItem('login'));
    console.log(items)
    if (!items) {
      this.setState({login : true})
    }
  }

  render() {
    return (
    <header class="topbar">
      {this.state.login && (
        <Navigate to="/login" replace={true} />
      )}
      <div class="topbar-left">
        
      <Link class="topbar-btn d-none d-md-block" to="#" data-provide="fullscreen tooltip" title="Fullscreen">
          <i class="material-icons fullscreen-default">fullscreen</i>
          <i class="material-icons fullscreen-active">fullscreen_exit</i>
        </Link>
        <div class="topbar-divider d-none d-md-block"></div>

        {/* <div class="lookup d-none d-md-block topbar-search" id="theadmin-search">
          <input class="form-control w-300px" type="text" />
          <div class="lookup-placeholder">
            <i class="ti-search"></i>
            <span data-provide="typing" data-type="&lt;strong&gt;Type&lt;/strong&gt; Button|&lt;strong&gt;Type&lt;/strong&gt; Slider|&lt;strong&gt;Type&lt;/strong&gt; Layout|&lt;strong&gt;Type&lt;/strong&gt; Modal|&lt;strong&gt;Try&lt;/strong&gt; typing any keyword..." data-loop="false" data-type-speed="90" data-back-speed="50" data-show-cursor="false"></span>
          </div>
        </div> */}
      </div>

      <div class="topbar-right">
        {/* <Link class="topbar-btn" to="#qv-global" data-toggle="quickview"><i class="ti-align-right"></i></Link> */}

        <div class="topbar-divider"></div>

        <ul class="topbar-btns">
          <li class="dropdown">
            <span class="topbar-btn" data-toggle="dropdown"><img className="avatar" src={this.state.imageUrl} alt="avatar" /></span>
            <div class="dropdown-menu dropdown-menu-right">
              {/* <Link class="dropdown-item" to="page/profile.html"><i class="ti-user"></i> Profile</Link>
              <Link class="dropdown-item" to="page-app/mailbox.html">
                <div class="flexbox">
                  <i class="ti-email"></i>
                  <span class="flex-grow">Inbox</span>
                  <span class="badge badge-pill badge-info">5</span>
                </div>
              </Link> */}
              {/* <Link class="dropdown-item" to="#"><i class="ti-settings"></i> Settings</Link> */}
              <div class="dropdown-divider"></div>
              {/* <Link class="dropdown-item" to="page-extra/user-lock-1.html"><i class="ti-lock"></i> Lock</Link> */}
              <Link class="dropdown-item" to="/login" onClick={this.logOut}><i class="ti-power-off"></i> Logout</Link>
            </div>
          </li>

           {/* Notifications */}
          {/* <li class="dropdown d-none d-md-block">
            <span class="topbar-btn has-new" data-toggle="dropdown"><i class="ti-bell"></i></span>
            <div class="dropdown-menu dropdown-menu-right">

              <div class="media-list media-list-hover media-list-divided media-list-xs">
                <Link class="media media-new" to="#">
                  <span class="avatar bg-success"><i class="ti-user"></i></span>
                  <div class="media-body">
                    <p>New user registered</p>
                    <time datetime="2018-07-14 20:00">Just now</time>
                  </div>
                </Link>

                <Link class="media" to="#">
                  <span class="avatar bg-info"><i class="ti-shopping-cart"></i></span>
                  <div class="media-body">
                    <p>New order received</p>
                    <time datetime="2018-07-14 20:00">2 min ago</time>
                  </div>
                </Link>

                <Link class="media" to="#">
                  <span class="avatar bg-warning"><i class="ti-face-sad"></i></span>
                  <div class="media-body">
                    <p>Refund request from <b>Ashlyn Culotta</b></p>
                    <time datetime="2018-07-14 20:00">24 min ago</time>
                  </div>
                </Link>

              </div>

              <div class="dropdown-footer">
                <div class="left">
                  <Link to="#">Read all notifications</Link>
                </div>

                <div class="right">
                  <Link to="#" data-provide="tooltip" title="Mark all as read"><i class="fa fa-circle-o"></i></Link>
                  <Link to="#" data-provide="tooltip" title="Update"><i class="fa fa-repeat"></i></Link>
                  <Link to="#" data-provide="tooltip" title="Settings"><i class="fa fa-gear"></i></Link>
                </div>
              </div>

            </div>
          </li> */}
           {/* END Notifications */}

           {/* Messages  */}
          {/* <li class="dropdown d-none d-md-block">
            <span class="topbar-btn" data-toggle="dropdown"><i class="ti-email"></i></span>
            <div class="dropdown-menu dropdown-menu-right">

              <div class="media-list media-list-divided media-list-hover media-list-xs scrollable" style={{height: '290px'}}>
                <Link class="media media-new1" to="page-app/mailbox-single.html">
                  <span class="avatar status-success">
                    <img src={require('../../assets/assets_theme/img/avatar/1.jpg')} alt="avatar" />
                  </span>

                  <div class="media-body">
                    <p><strong>Maryam Amiri</strong> <time class="float-right" datetime="2018-07-14 20:00">23 min ago</time></p>
                    <p class="text-truncate">Authoritatively exploit resource maximizing technologies before technically.</p>
                  </div>
                </Link>

                <Link class="media media-new1" to="page-app/mailbox-single.html">
                  <span class="avatar status-warning">
                    <img src={require('../../assets/assets_theme/img/avatar/2.jpg')} alt="avatar" />
                  </span>

                  <div class="media-body">
                    <p><strong>Hossein Shams</strong> <time class="float-right" datetime="2018-07-14 20:00">48 min ago</time></p>
                    <p class="text-truncate">Continually plagiarize efficient interfaces after bricks-and-clicks niches.</p>
                  </div>
                </Link>

                <Link class="media" to="page-app/mailbox-single.html">
                  <span class="avatar status-dark">
                    <img src={require('../../assets/assets_theme/img/avatar/3.jpg')} alt="avatar" />
                  </span>

                  <div class="media-body">
                    <p><strong>Helen Bennett</strong> <time class="float-right" datetime="2018-07-14 20:00">3 hours ago</time></p>
                    <p class="text-truncate">Objectively underwhelm cross-unit web-readiness before sticky outsourcing.</p>
                  </div>
                </Link>

                <Link class="media" to="page-app/mailbox-single.html">
                  <span class="avatar status-success bg-purple">FT</span>

                  <div class="media-body">
                    <p><strong>Fidel Tonn</strong> <time class="float-right" datetime="2018-07-14 20:00">21 hours ago</time></p>
                    <p class="text-truncate">Interactively innovate transparent relationships with holistic infrastructures.</p>
                  </div>
                </Link>

                <Link class="media" to="page-app/mailbox-single.html">
                  <span class="avatar status-danger">
                    <img src={require('../../assets/assets_theme/img/avatar/4.jpg')} alt="avatar" />
                  </span>

                  <div class="media-body">
                    <p><strong>Freddie Arends</strong> <time class="float-right" datetime="2018-07-14 20:00">Yesterday</time></p>
                    <p class="text-truncate">Collaboratively visualize corporate initiatives for web-enabled value.</p>
                  </div>
                </Link>
              </div>

              <div class="dropdown-footer">
                <div class="left">
                  <Link to="#">Read all messages</Link>
                </div>

                <div class="right">
                  <Link to="#" data-provide="tooltip" title="Mark all as read"><i class="fa fa-circle-o"></i></Link>
                  <Link to="#" data-provide="tooltip" title="Settings"><i class="fa fa-gear"></i></Link>
                </div>
              </div>

            </div>
          </li> */}
           {/* END Messages */}

        </ul>

        <div class="topbar-divider"></div>

        {/* <Link class="btn btn-xs btn-round btn-success mr-2" to="https://themeforest.net/item/theadmin-responsive-bootstrap-4-admin-dashboard-webapp-template/20475359?license=regular&amp;open_purchase_for_item_id=20475359&amp;purchasable=source&amp;ref=thethemeio">Buy TheAdmin</Link> */}

      </div>
    </header>
  )
}
}

export default Topbar;