import React, {Component} from "react";
import { Outlet } from "react-router-dom";
import Sidebar from './Components/Bar/Sidebar';
import Topbar from './Components/Bar/Topbar';
import Footer from "./Components/Bar/Footer";

export default class Container extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       nb_click: 0,
    //       pathname: "/",
    //     }
    //   }

    //   componentWillMount() {
    //    setInterval(() => {
    //     if (this.state.pathname !== window.location.pathname) {
    //         this.setState({pathname: window.location.pathname});
    //     }
    //    }, 100);
    //   }

      render() {
        return <div  className="App">
          <Sidebar />      
          <Topbar />

          
          <main className="main-container">
              <div className="main-content">
                  <div className="row">
                  <Outlet />
                  </div>
              </div>
              <Footer />
          </main>
          
        </div>
  
      }
}