import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import AboutUs from "./Components/Setting/AboutUs";
import CategoryIndex from "./Components/Category/CategoryIndex";
import CategoryEdit from "./Components/Category/CategoryEdit";
import CategoryNew from "./Components/Category/CategoryNew";
import CategoryView from "./Components/Category/CategoryView";
import Container from "./Container";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import Register from "./Components/Register";
import TermAndCondition from "./Components/Setting/TermAndCondition";
import UserIndex from "./Components/User/UserIndex";
import UserNew from "./Components/User/UserNew";
import UserEdit from "./Components/User/UserEdit";
import UserView from "./Components/User/UserView";
import Videoindex from "./Components/Video/VideoIndex";
import VideoNew from "./Components/Video/VideoNew";
import VideoEdit from "./Components/Video/VideoEdit";
import VideoView from "./Components/Video/VideoView";

import "./index.css";
import NewsNew from "./Components/News/NewsNew";
import NewsEdit from "./Components/News/NewsEdit";
import NewsView from "./Components/News/NewsView";
import NewsIndex from "./Components/News/NewsIndex";
import Error404 from "./Components/Error404";

// Report
import SellReport from "./Components/Report/SellReport";
import SubscriptionReport from "./Components/Report/SubscriptionReport";
import UserReport from "./Components/Report/UserReport";
import VideoReport from "./Components/Report/VideoReport";

//User Management
import Role from "./Components/UserManagement/role";
import Permission from "./Components/UserManagement/permission";


const router = createBrowserRouter([
  {
    path: "/login",
        element: <Login />
  },
  {
    path: "/register",
        element: <Register />
  },
  {
    path: "*",
        element: <Error404 />
  },
  {
    path: "/",
    element: <Container/>,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "userindex",
        element: <UserIndex />,
      },
      {
        path: "usernew",
        element: <UserNew />,
      },
      {
        path: "useredit",
        element: <UserEdit />,
      },
      {
        path: "userview",
        element: <UserView />,
      },
      {
        path: "videoindex",
        element: <Videoindex />,
      },
      {
        path: "videoedit",
        element: <VideoEdit />,
      },
      {
        path: "videoview",
        element: <VideoView />,
      },
      {
        path: "videonew",
        element: <VideoNew />,
      },
      {
        path: "categoryindex",
        element: <CategoryIndex />,
      },
      {
        path: "categorynew",
        element: <CategoryNew />,
      },
      {
        path: "categoryedit",
        element: <CategoryEdit />,
      },
      {
        path: "categoryview",
        element: <CategoryView />,
      },
      {
        path: "newsindex",
        element: <NewsIndex />,
      },
      {
        path: "newsnew",
        element: <NewsNew />,
      },
      {
        path: "newsedit",
        element: <NewsEdit />,
      },
      {
        path: "newsview",
        element: <NewsView />,
      },
      {
        path: "/usersreport",
        element: <UserReport />,
      },
      {
        path: "/videosreport",
        element: <VideoReport />,
      },
      {
        path: "/subscriptionreport",
        element: <SubscriptionReport />,
      },
      {
        path: "/sellreport",
        element: <SellReport />,
      },
      {
        path: "/role",
        element: <Role />,
      },
      {
        path: "/permission",
        element: <Permission />,
      },
      {
        path: "/aboutus",
        element: <AboutUs />,
      },
      {
        path: "/termandcondition",
        element: <TermAndCondition />,
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <BrowserRouter>
  //   <App />
  // </BrowserRouter>,

  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);