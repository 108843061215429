import React, {Component} from "react";

class Dashboard extends Component {
  
  constructor(props) {
    super(props)
  
    this.state = {
       reload: false
    }
  }
  // constructor(props) {
  //   super(props);
  //   var chartjs2 = new Chart(document.getElementById("chart-js-2"), {
  //     type: 'line',
  //     data: {
  //       labels: ["2012", "2013", "2014", "2015", "2016", "2017", "2018"],
  //       datasets: [
  //         {
  //           label: "Advertise",
  //           backgroundColor: "rgba(51,202,185,0.5)",
  //           borderColor: "rgba(51,202,185,0.5)",
  //           pointRadius: 0,
  //           data: [0, 6000, 8000, 5000, 2000, 5000, 7500]
  //         },
  //         {
  //           label: "Hosting",
  //           backgroundColor: "rgba(243,245,246,0.8)",
  //           borderColor: "rgba(243,245,246,0.8)",
  //           pointRadius: 0,
  //           data: [9000, 5000, 4000, 2000, 8000, 3000, 9000]
  //         }
  //       ]
  //     },
  //     options: {
  //       legend: {
  //         display: false
  //       },
  //     }
  //   });

    
  // }
  
  

  render() {
    return (
        <div className="row">
          <div className="col-md-4">
            <div className="card card-body">
              <div className="flexbox">
                <div data-provide="sparkline" data-width="100" data-fill-color="false" data-line-width="2">1,4,3,7,6,4,8,9,6,8,12</div>
                <div className="text-right">
                  <span className="fw-400">New Users</span><br />
                  <span>
                    <i className="ti-angle-up text-success"></i>
                    <span className="fs-18 ml-1">113</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-body">
              <div className="flexbox">
                <div data-provide="sparkline" data-type="bar" data-bar-color="#f2a654">1,4,7,6,4,8,6,12</div>
                <div className="text-right">
                  <span className="fw-400">Monthly Sale</span><br />
                  <span>
                    <i className="ti-angle-up text-success"></i>
                    <span className="fs-18 ml-1">%80</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-body">
              <div className="flexbox">
                <div data-provide="sparkline" data-type="discrete" data-width="50" data-line-color="#926dde">1,4,3,7,6,4,8,9,6,8,12</div>
                <div className="text-right">
                  <span className="fw-400">Impressions</span><br />
                  <span>
                    <i className="ti-angle-up text-success"></i>
                    <span className="fs-18 ml-1">%80</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title"><strong>Earnings</strong></h5>
                <a className="btn btn-xs btn-secondary" href="../widget/chart.html">More Charts</a>
              </div>

              <div className="card-body">
                <ul className="list-inline text-center gap-items-4 mb-30">
                  <li className="list-inline-item">
                    <span className="badge badge-lg badge-dot mr-1" style={{backgroundColor: "#b1bccb"}}></span>
                    <span>Advertising</span>
                  </li>
                  <li className="list-inline-item">
                    <span className="badge badge-lg badge-dot mr-1" style={{backgroundColor: "#01c4cc"}}></span>
                    <span>Hosting</span>
                  </li>
                </ul>
                {/* canvas not working  */}
                {/* <canvas id="chart-js-2" style={{height: "130"}} data-provide="chartjs"></canvas> */}
                <canvas id="chart-js-2" style={{height: "130"}} data-provide="chartjs" />
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title"><strong>Top</strong> Advertisers</h5>

                <ul className="card-controls">
                  <li className="dropdown">
                    <a data-toggle="dropdown" href="..."><i class="ti-more-alt rotate-90"></i> </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item active" href="...">Today</a>
                      <a className="dropdown-item" href="...">Yesterday</a>
                      <a className="dropdown-item" href="...">Last week</a>
                      <a className="dropdown-item" href="...">Last month</a>
                    </div>
                  </li>
                  <li><a className="card-btn-reload" href="..." title="Refresh" data-provide="tooltip"><i class="fa fa-circle-thin"></i></a></li>
                </ul>
              </div>

              <div className="card-body card-body">
                <p className="text-center d-none d-xl-block">We need to stop interrupting what people are and be what people are interested in. We need to stop interrupting what people are and be what people are interested in.</p>

                <div className="text-center py-20 d-none d-lg-block">
                  <div data-provide="peity" data-type="donut" data-size="150" data-inner-radius="55" data-fill="#efb3e6,#ffdf7c,#b2def7">9,6,5</div>
                </div>

                <div className="text-center py-20 d-lg-none">
                  <div data-provide="peity" data-type="donut" data-size="87" data-inner-radius="35" data-fill="#efb3e6,#ffdf7c,#b2def7">9,6,5</div>
                </div>

                <br />

                <ul className="list-inline mb-0">
                  <li className="flexbox mb-1">
                    <div>
                      <span className="badge badge-dot badge-lg mr-1" style={{backgroundColor: "#efb3e6"}}></span>
                      <span>Google</span>
                    </div>
                    <div>953</div>
                  </li>

                  <li className="flexbox mb-1">
                    <div>
                      <span className="badge badge-dot badge-lg mr-1" style={{backgroundColor: "#ffdf7c"}}></span>
                      <span>Facebook</span>
                    </div>
                    <div>813</div>
                  </li>

                  <li class="flexbox">
                    <div>
                      <span className="badge badge-dot badge-lg mr-1" style={{backgroundColor: "#b2def7"}}></span>
                      <span>Twitter</span>
                    </div>
                    <div>369</div>
                  </li>
                </ul>
              </div>
            </div>
          </div> 

            <div className="col-12">
                <div className="divider text-uppercase fw-500">Social</div>
             </div>

          <div className="col-md-6 col-lg-4">
            <div className="card p-30 pt-50 text-center">
              <div>
                <a className="avatar avatar-xxl status-success mb-3" href="../page/profile.html">
                  <img src={require('../assets/assets_theme/img/avatar/1.jpg')} alt="..." />  
                </a>
              </div>
              <h5><a href="../page/profile.html">Maryam Amiri</a></h5>
              <p><small className="fs-13">Designer</small></p>
              <p className="text-light fs-12 mb-30">Hello everyone, I am Maryam. My designs are used in several big companies in United State and other countries.</p>
              <div className="gap-items fs-16">
                <a className="text-facebook" href="/"><i className="fa fa-facebook"></i></a>
                <a className="text-dribbble" href="/"><i className="fa fa-dribbble"></i></a>
                <a className="text-google" href="/"><i className="fa fa-google"></i></a>
                <a className="text-twitter" href="/"><i className="fa fa-twitter"></i></a>
              </div>
            </div>
          </div> 

          <div className="col-md-6 col-lg-4">
            <div className="card">
              <h5 className="card-title"><strong>Friends</strong></h5>

              <div className="media-list media-list-sm media-list-hover media-list-divided scrollable" style={{height: "295px"}}>
                <div className="media media-single">
                  <a href="/">
                  <img className= "avatar" src={require('../assets/assets_theme/img/avatar/2.jpg')} alt="..." /> 
                  </a>

                  <div className="media-body">
                    <h6><a href="/">Hossein Shams</a></h6>
                    <small className="text-fader">Co-Founder</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-outline btn-secondary w-100px" href="/">Followed</a>
                  </div>
                </div>


                <div class="media media-single">
                  <a href="/">
                    <img className= "avatar" src={require('../assets/assets_theme/img/avatar/1.jpg')} alt="..." /> 
                  </a>

                  <div class="media-body">
                    <h6><a href="/">Maryam Amiri</a></h6>
                    <small class="text-fader">Co-Founder</small>
                  </div>

                  <div class="media-right">
                    <a class="btn btn-sm btn-bold btn-round btn-outline btn-secondary w-100px" href="/">Followed</a>
                  </div>
                </div>


                <div className="media media-single">
                  <a href="/">
                    <img className= "avatar" src={require('../assets/assets_theme/img/avatar/1.jpg')} alt="..." /> 
                  </a>

                  <div className="media-body">
                    <h6><a href="/">Sarah Conner</a></h6>
                    <small className="text-fader">Designer</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-success w-100px" href="/">Follow</a>
                  </div>
                </div>

                <div className="media media-single">
                  <a href="/">
                    <img className="avatar" src={require('../assets/assets_theme/img/avatar/4.jpg')} alt="..." />
                  </a>

                  <div className="media-body">
                    <h6><a href="/">Frank Camly</a></h6>
                    <small className="text-fader">CTO</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-success w-100px" href="/">Follow</a>
                  </div>
                </div>

                <div className="media media-single">
                  <a href="/">
                    <img className="avatar" src={require('../assets/assets_theme/img/avatar/5.jpg')} alt="..." />
                  </a>

                  <div className="media-body">
                    <h6><a href="/">Ted Erricson</a></h6>
                    <small className="text-fader">Lead Developer</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-outline btn-secondary w-100px" href="/">Followed</a>
                  </div>
                </div>

                <div className="media media-single">
                  <a href="/">
                    <img className="avatar" src={require('../assets/assets_theme/img/avatar/6.jpg')} alt="..." />
                  </a>

                  <div className="media-body">
                    <h6><a href="/">Ranian Mostalik</a></h6>
                    <small className="text-fader">Senior Developer</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-success w-100px" href="/">Follow</a>
                  </div>
                </div>

                <div className="media media-single">
                  <a href="/">
                    <img className="avatar" src={require('../assets/assets_theme/img/avatar/7.jpg')} alt="..." />
                  </a>

                  <div className="media-body">
                    <h6><a href="/">John Franklin</a></h6>
                    <small className="text-fader">Front-end Developer</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-success w-100px" href="/">Follow</a>
                  </div>
                </div>

                <div className="media media-single">
                  <a href="/">
                    <img className="avatar" src={require('../assets/assets_theme/img/avatar/8.jpg')} alt="..." />
                  </a>

                  <div class="media-body">
                    <h6><a href="/">Emma Larson</a></h6>
                    <small class="text-fader">PHP Guru</small>
                  </div>

                  <div className="media-right">
                    <a className="btn btn-sm btn-bold btn-round btn-success w-100px" href="/">Follow</a>
                  </div>
                </div>
              </div>

              <div className="text-center bt-1 border-light p-2">
                <a className="text-default text-uppercase d-block fs-10 fw-500 ls-1" href="/">Invite Friends</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-none d-lg-block">
            <div className="flexbox flex-justified text-center bg-white mb-15">
              <div className="no-shrink py-30">
                <span className="ti-facebook fs-40" style={{color: "#3b5998"}}></span>
              </div>

              <div className="py-30 bg-lighter">
                <div className="fs-30">+100</div>
                <span>Likes</span>
              </div>
            </div>

            <div className="flexbox flex-justified text-center bg-white mb-15">
              <div className="no-shrink py-30">
                <span className="ti-twitter fs-40" style={{color: "#00aced"}}></span>
              </div>

              <div className="py-30 bg-lighter">
                <div className="fs-30">+99</div>
                <span>Followers</span>
              </div>
            </div>

            <div className="flexbox flex-justified text-center bg-white">
              <div className="no-shrink py-30">
                <span className="ti-dribbble fs-40" style={{color: "#ea4c89"}}></span>
              </div>

              <div className="py-30 bg-lighter">
                <div className="fs-30">+45</div>
                <span>Shots</span>
              </div>
            </div>
          </div> 

          <div className="col-12 d-none d-md-block">
            <div className="card">

              <div className="row no-gutters">
                <a className="col-4 bg-img" style={{ backgroundImage: `url(${require("../assets/assets_theme/img/gallery/thumb/8.jpg")})` }} href="..."> </a>

                <div className="col-8">
                  <div className="card-body">
                    <h5><a className="hover-primary text-uppercase fs-14 fw-500 ls-1" href="../widget/blog.html">Quick start - jump into writing code</a></h5>
                    <hr className="w-50px ml-0 bt-2 border-pink hr-sm" />

                    <p>Holisticly maximize team building via next-generation resources. Enthusiastically promote team driven customer service and error-free solutions. Dynamically myocardinate vertical leadership for synergistic "outside the box" thinking. Efficiently extend global. Competently leverage existing one-to-one schemas after value-added opportunities. Rapidiously drive worldwide mindshare rather than vertical customer service. Globally coordinate adaptive materials before clicks-and-mortar supply chains.</p>

                    <div className="flexbox flex-items-middle mt-30">
                      <a href="...">
                        <img className="avatar avatar-sm" src={require('../assets/assets_theme/img/avatar/2.jpg')} alt="..." />
                        <span className="text-default fw-400 ml-2">Hos Shams</span>
                      </a>

                      <a href="...">
                        <i className="fa fa-heart text-danger fs-11"></i>
                        <span className="fs-11 text-fade ml-1">128</span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div> 
        </div>
        
    );
  }
}



export default Dashboard;
