import React, { Component } from 'react';
import { Link } from "react-router-dom";
import API from "../../config/api";

export class CategoryView extends Component {

    constructor(props) {
        super(props)
      
        this.state = {
            category_id: 1,
            categories: [],
        }
      }
      UNSAFE_componentWillMount() {
        this.getParam("id");
        this.getOneCategory();
      }

      getParam(name) {
        let id = window.location.search.split(`${name}=`)[1]
        this.state.category_id = id;
        // this.setState({category_id : id})
        this.setState({})
        // return 
      }
    
      getOneCategory = async () => {
        try {
          let query = `category_id=${this.state.category_id}`
          let results = await API.getOneCategory(query);
    
          let data = results.data;
          console.log(data)
          this.setState({ categories : data });
    
        } catch (error) {
          console.log(error)
        }
      }
    
  render() {
    return (
        <div className="col-12">
            <form className="card">
                <h4 className="card-title"><strong>Category</strong></h4>

                <div className="card-body row">

                    <div className="col-md-6">
                    <div className="form-group">
                        <label className="require" for="input-required">Name</label>
                        <input type="text" className="form-control" id="input-required" value={this.state.categories.name_kh} disabled />
                    </div>
                    {/* <div className="form-group">
                        <label for="inputState" className="col-form-label">Parent</label>
                        <select id="inputState" className="form-control">
                        <option>Short Film</option>
                        <option>Funny</option>
                        <option>Love story</option>
                        <option>Sad story</option>
                        </select>
                    </div> */}
                    
                    </div>


                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Description</label>
                        <input type="text" className="form-control" id="input-required" value={this.state.categories.name_en} disabled />
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                        <label>Thumbnail</label>
                        <img className='h-300px w-fullscreen' src={this.state.categories.thumbnail_url} alt="..." />
                        <br />
                        {/* <input type="file" data-provide="dropify" /> */}
                    </div>
                    </div>
                    

                    
                </div>

                <footer className="card-footer text-right">
                    <Link className="btn btn-secondary" to="/categoryindex">Back</Link>
                </footer>
            </form>
        </div>
    )
  }
}

export default CategoryView