import React, {Component} from 'react'
import { Link } from 'react-router-dom'

class Sidebar extends Component {
  constructor(props) {
      super(props);
      this.state = {
        nb_click: 0,
        pathname: "/",
      }
    }

    componentDidMount() {
     setInterval(() => {
      if (this.state.pathname !== window.location.pathname) {
          this.setState({pathname: window.location.pathname});
      }
     }, 100);
    }

    render() {
      return (
      <aside className="sidebar sidebar-icons-right sidebar-icons-boxed sidebar-expand-lg">
      <header className="sidebar-header">
        <Link className="logo-icon" to="#">
            <img src={require('../../assets/assets_theme/img/logo-icon-light.png')} alt="logo icon" />
        </Link>
        <span className="logo">
            <Link to=""><img src={require('../../assets/assets_theme/img/logo-light.png')} alt="logo" /></Link>
        </span>
        <span className="sidebar-toggle-fold"></span>
      </header>
     

      <nav className="sidebar-navigation">
        <ul className="menu">

          <li className="menu-category">Preview</li>

          <li className={`menu-item ${this.state.pathname === "/"? "active" : ""} `}>
            <Link className="menu-link" to="/">
              <span className="icon fa fa-home"></span>
              <span className="title">Dashboard</span>
            </Link>
          </li>

          <li className={`menu-item ${this.state.pathname=== "/userindex" || this.state.pathname=== "/useredit" || this.state.pathname=== "/userview" ? "active" : ""} `}>
            <Link className="menu-link" to="/userindex">
              <span className="icon fa fa-users"></span>
              <span className="title">User</span>
            </Link>
          </li>
          
          <li class={`menu-item ${this.state.pathname === "/videoindex" || this.state.pathname=== "/videoedit" || this.state.pathname=== "/videoview" ? "active" : ""} `}>
            <Link className="menu-link" to="/videoindex">
              <span className="icon fa fa-video-camera"></span>
              <span className="title">Video</span>
            </Link>
          </li>
          <li className={`menu-item ${this.state.pathname.includes('/category') ? "active" : ""} `}>
                <Link className="menu-link" to="/categoryindex">
                  <span className="icon fa fa-folder-open-o"></span>
                  <span className="title">Category</span>
                </Link>
          </li>
          <li className={`menu-item ${this.state.pathname.includes('/news') ? "active" : ""} `}>
                <Link className="menu-link" to="/newsindex">
                  <span className="icon fa fa-newspaper-o"></span>
                  <span className="title">News</span>
                </Link>
          </li>

          <li className={`menu-item ${ this.state.pathname.includes('report') ? "active" : ""} `}>
            <Link className="menu-link" to="#">
              <span className="icon ti-settings"></span>
              <span className="title">Report</span>
              <span className="arrow"></span>
            </Link>

            <ul className="menu-submenu">
              <li className={`menu-item ${this.state.pathname.includes('/usersreport') ? "active" : ""} `}>
                <Link className="menu-link" to="/usersreport" >
                  <span className="dot"></span>
                  <span className="title">Users</span>
                </Link>
              </li>
              <li className={`menu-item ${this.state.pathname.includes('/videosreport') ? "active" : ""} `}>
                <Link className="menu-link" to="/videosreport" >
                  <span className="dot"></span>
                  <span className="title">Videos</span>
                </Link>
              </li>
              <li className={`menu-item ${this.state.pathname.includes('/subscriptionreport') ? "active" : ""} `}>
                <Link className="menu-link" to="/subscriptionreport" >
                  <span className="dot"></span>
                  <span className="title">Subscription</span>
                </Link>
              </li>
              <li className={`menu-item ${this.state.pathname.includes('/sellreport') ? "active" : ""} `}>
                <Link className="menu-link" to="/sellreport" >
                  <span className="dot"></span>
                  <span className="title">Sell</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`menu-item ${ this.state.pathname.includes('role') || this.state.pathname.includes('permission') ? "active" : ""} `}>
            <Link className="menu-link" to="#">
              <span className="icon ti-settings"></span>
              <span className="title">User Management</span>
              <span className="arrow"></span>
            </Link>

            <ul className="menu-submenu">
              <li className={`menu-item ${this.state.pathname.includes('/role') ? "active" : ""} `}>
                <Link className="menu-link" to="/role" >
                  <span className="dot"></span>
                  <span className="title">Role</span>
                </Link>
              </li>
              <li className={`menu-item ${this.state.pathname.includes('/permission') ? "active" : ""} `}>
                <Link className="menu-link" to="/permission" >
                  <span className="dot"></span>
                  <span className="title">Permission</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`menu-item ${ this.state.pathname.includes('termandcondition') || this.state.pathname.includes('aboutus') ? "active" : ""} `}>
            <Link className="menu-link" to="#">
              <span className="icon ti-settings"></span>
              <span className="title">Setting</span>
              <span className="arrow"></span>
            </Link>

            <ul className="menu-submenu">
              <li className={`menu-item ${this.state.pathname.includes('/termandcondition') ? "active" : ""} `}>
                <Link className="menu-link" to="/termandcondition" >
                  <span className="dot"></span>
                  <span className="title">Terms and Conditions</span>
                </Link>
              </li>
              <li className={`menu-item ${this.state.pathname.includes('/aboutus') ? "active" : ""} `}>
                <Link className="menu-link" to="/aboutus" >
                  <span className="dot"></span>
                  <span className="title">About Us</span>
                </Link>
              </li>
            </ul>
          </li>

        </ul>
        
      </nav>
    </aside>
    );
    }
}



export default Sidebar