import React, {Component} from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import API from "../../config/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class VideoView extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      video_id : 1, 
      video: [],
      episodes: [],
      tags: [],
      options : [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    }
  }

  UNSAFE_componentWillMount() {
    this.getParam("id");
    this.getOneVideo();
  }

  getParam(name) {
    let id = window.location.search.split(`${name}=`)[1]
    this.state.video_id = id;
    // this.setState({category_id : id})
    this.setState({})
    // return 
  }

  getOneVideo = async () => {
    try {
      let query = `video_id=${this.state.video_id}`
      let results = await API.getOneVideo(query);

      let data = results.data;
      console.log(data)
      this.setState({ video : data.video, episodes: data.episode, tags: data.tag });

    } catch (error) {
      console.log(error)
    }
  }


  render() {
    this.state.tags = this.state.tags.map(item => {
      return {
        value: item.name_en,
        label: item.name_en
      }
    })
    return (
        <div className="col-12">
          
            {/* <div class="card card-inverse bg-img" style={{backgroundImage: `url(${this.state.imageUrl})`}} data-overlay="2">
              <div class="flexbox px-20 pt-20">
                <label class="toggler toggler-danger text-white">
                  <input type="checkbox" />
                  <i class="fa fa-heart"></i>
                </label>
                <div class="dropdown">
                  <Link data-toggle="dropdown" to="#"><i class="ti-more-alt rotate-90 text-white"></i></Link>
                  <div class="dropdown-menu dropdown-menu-right">
                    <Link class="dropdown-item" to="#"><i class="fa fa-user"></i> Profile</Link>
                    <Link class="dropdown-item" to="#"><i class="fa fa-picture-o"></i> Shots</Link>
                    <Link class="dropdown-item" to="#"><i class="ti-check"></i> Follow</Link>
                    <div class="dropdown-divider"></div>
                    <Link class="dropdown-item" to="#"><i class="fa fa-ban"></i> Block</Link>
                  </div>
                </div>
              </div>

              

              <ul class="card-body flexbox flex-justified text-center" data-overlay="4">
                <li>
                  <span class="opacity-60">Love</span><br />
                  <span class="fs-22">3.6K</span>
                </li>
                <li>
                  <span class="opacity-60">Following</span><br />
                  <span class="fs-22">426</span>
                </li>
                <li>
                  <span class="opacity-60">Tweets</span><br />
                  <span class="fs-22">165</span>
                </li>
              </ul>
            </div> */}
            <form className="card">
              <h4 className="card-title"><strong>Video Information</strong></h4>
              <div className="card-title row">

                <div className="col-md-6">
                  <div className="form-group">
                      <label className="require" for="input-required">Title Kh</label>
                      <input type="text" className="form-control" id="input-required" value={this.state.video.title_kh} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description Kh </label>
                      <textarea type="text" className="form-control" id="input-required" value={this.state.video.description_kh} />
                  </div>
                  
                  <div className="form-group">
                    <label> Totla price</label>
                    <small className="sidetitle">E.g. $ 999.99</small>
                    <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" value={"$ " + this.state.video.total_price} />
                  </div>
                  <div className="form-group">
                    <label>Release date</label>
                    {/* <input className="form-control" type="date" value={this.state.video.created_at} /> */}
                    <input className="form-control" type="datetime-local" value={this.state.video.created_at} />
                  </div>
                  <div className="form-group">
                    <label> Totla rating</label>
                    <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" value={this.state.video.total_rating} />
                  </div>
                  <div className="form-group">
                    <label>Thumbnail</label>
                    <div class="input-group file-group">
                      <img className='h-300px w-fullscreen' src={this.state.video.thumbnail_url} alt="..." />
                      {/* <input type="text" class="form-control file-value" placeholder="Choose file..." readonly /> */}
                      {/* <input type="file" multiple /> */}
                      <span class="input-group-append">
                        {/* <button class="btn btn-light file-browser" type="button"><i class="fa fa-upload"></i></button> */}
                      </span>
                    </div>
                  </div>
                </div>
                


                <div className="col-md-6">
                  <div className="form-group">
                    <label>Title En</label>
                    <input className="form-control" type="text" value={this.state.video.title_en} />
                  </div>
                  <div className="form-group">
                      <label className="require" for="input-required">Description En</label>
                      <textarea type="text" className="form-control" id="input-required" value={this.state.video.description_en} />
                  </div>

                  
                  <div className="form-group">
                    <label>Discount</label>
                    <small className="sidetitle">E.g. %25.46</small>
                    <input type="text" className="form-control" data-format="%{{99}}.{{99}}" value={this.state.video.discount + ' %'} />
                  </div>

                  
                  <div className="form-group">
                    {/* <label for="inputState" className="col-form-label">Tag</label> */}
                    <label>Tag</label>
                    <Select
                        // defaultValue={[this.state.options[2], this.state.options[3]]}
                        value={this.state.tags}
                        isMulti
                        name="colors"
                        // options={this.state.options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isDisabled
                      />
                      {/* <Select 
                        defaultValue={[this.state.tags[2], this.state.tags[3]]}
                        value={this.state.tags.name_en}
                        options={this.state.tags.name_en} 
                      /> */}
                  </div>
                  <div className="form-group">
                    <label>Rating</label>
                    <input className="form-control" type="text" value={this.state.video.rating} />
                  </div>
                  <div className="form-group">
                      <label>Preview Video</label>
                      <div class="card">
                        <div class="embed-responsive embed-responsive-16by9 h-300px">
                          <iframe src={this.state.video.preview_video_url} title="video1080" frameborder="0" allowfullscreen=""></iframe>
                        </div>


                        <div class="card-body py-12">
                          <div class="gap-items-4">
                            <a class="text-lighter hover-light" href="/">
                              <i class="fa fa-thumbs-up mr-1"></i> 196
                            </a>
                            <a class="text-lighter hover-light" href="/">
                              <i class="fa fa-comment mr-1"></i> 24
                            </a>
                            <a class="text-lighter hover-light" href="/">
                              <i class="fa fa-share-alt mr-1"></i> 3
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <input type="file" data-provide="dropify" /> */}
                      {/* <div style={{textAlign:'center'}}> 
                        <input type="text" value="100" data-provide="knob"></input>
                      </div> */}
                  </div>
                  <br />
                </div>
              </div>
            </form>
            {
              this.state.episodes.map((episode, index) => {
                return <form className="card">
                <h4 className="card-title"><strong>Episode {index + 1}</strong></h4>
                <div className="card-body row">
  
                  <div className="col-md-6">
                    <div className="form-group">
                        <label className="require" for="input-required">Title Kh</label>
                        <input type="text" className="form-control" id="input-required" value={episode.title_kh} />
                    </div>
                    <div className="form-group">
                        <label className="require" for="input-required">Description Kh</label>
                        <textarea type="text" className="form-control" id="input-required" value={episode.description_kh} />
                    </div>
                    
                    <div className="form-group">
                      <label> Totla price</label>
                      <small className="sidetitle">E.g. $ 999.99</small>
                      <input type="text" className="form-control" data-format="$ {{999}}.{{99}}" value={"$ " + episode.price} />
                    </div>
                    <div className="form-group">
                      <label>Release date</label>
                      {/* <input className="form-control" type="date" value={this.state.video.created_at} /> */}
                      <input className="form-control" type="datetime-local" value={episode.created_at} />
                    </div>
                    <div className="form-group">
                      <label>Thumbnail</label>
                      <div class="input-group file-group">
                        <img className='h-300px w-fullscreen' src={episode.thumbnail_url} alt="..." />
                        {/* <input type="text" class="form-control file-value" placeholder="Choose file..." readonly /> */}
                        {/* <input type="file" multiple /> */}
                        <span class="input-group-append">
                          {/* <button class="btn btn-light file-browser" type="button"><i class="fa fa-upload"></i></button> */}
                        </span>
                      </div>
                    </div>
                  </div>
  
  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Title En</label>
                      <input className="form-control" type="text" value={episode.title_en} />
                    </div>
                    <div className="form-group">
                        <label className="require" for="input-required">Description En</label>
                        <textarea type="text" className="form-control" id="input-required" value={episode.description_en} />
                    </div> 
                    <div className="form-group">
                      <label>Discount</label>
                      <small className="sidetitle">E.g. %25.46</small>
                      <input type="text" className="form-control" data-format="%{{99}}.{{99}}" value={episode.discount + ' %'} />
                    </div>
                    <div className="form-group">
                      <label>Number view</label>
                      <input className="form-control" type="text" value={episode.nb_views} />
                    </div>
                  </div>
                  <div className="card-body row">
                    <div className="col-12">
                        <div className="form-group">
                          <h6 className="mb-1">Episode</h6>
                        </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label>Video 720</label>
                          <div class="card">
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe src={episode.video_url} title="video1080" frameborder="0" allowfullscreen=""></iframe>
                            </div>
  
  
                            <div class="card-body py-12">
                              <div class="gap-items-4">
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-thumbs-up mr-1"></i> 196
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-comment mr-1"></i> 24
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-share-alt mr-1"></i> 3
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <input type="file" data-provide="dropify" /> */}
                          {/* <div style={{textAlign:'center'}}> 
                            <input type="text" value="100" data-provide="knob"></input>
                          </div> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label>Video 1080</label>
                          <div class="card">
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe src={episode.video_url} title="video1080" frameborder="0" allowfullscreen=""></iframe>
                            </div>
                            <div class="card-body py-12">
                              <div class="gap-items-4">
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-thumbs-up mr-1"></i> 196
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-comment mr-1"></i> 24
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-share-alt mr-1"></i> 3
                                </a>
                              </div>
                            </div>
                          </div>
                          
  
                          {/* <input type="file" data-provide="dropify" /> */}
                          {/* <div style={{textAlign:'center'}}> 
                            <input type="text" value="50" data-provide="knob"></input>
                          </div> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                          <label>Video 4k</label>
  
                          <div class="card">
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe src={episode.video_url} title="video1080" frameborder="0" allowfullscreen=""></iframe>
                            </div>
  
  
                            <div class="card-body py-12">
                              <div class="gap-items-4">
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-thumbs-up mr-1"></i> 196
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-comment mr-1"></i> 24
                                </a>
                                <a class="text-lighter hover-light" href="/">
                                  <i class="fa fa-share-alt mr-1"></i> 3
                                </a>
                              </div>
                            </div>
                          </div>
                          
                          {/* <input type="file" data-provide="dropify" /> */}
                          {/* <div style={{textAlign:'center'}}> 
                            <input type="text" value="2" data-provide="knob" data-readonly="true"></input>
                          </div> */}
                      </div>
                    </div>
                  </div>
  
                  {/* <div className="card col-12">
                    
                    <div className="col-md-6">
                      <div className="form-group ">
                        <button className="btn btn-secondary"> Add <i className="ion-plus-round"></i></button>
                      </div>
                    </div>  
                  </div> */}
  
  
                  {/* <div className="card col-12">
                      <h4 className="card-title"><strong>Description</strong></h4>
  
                      <div className="card-body">
                          <pre><code className="language-markup">&lt;div id="editor-id" data-provide="quill" data-toolbar="full"&gt;&lt;/div&gt;</code></pre>
                          <div id="editor-full" data-provide="quill" data-toolbar="full">
                            <h1 className="ql-align-center">Video Description</h1>
                          </div>
                      </div>
                  </div> */}
                  </div>
              </form>
              })  
            }
            <footer className="card-footer text-right">
                  {/* <Link to="/videoindex"><button className="btn btn-secondary" type="reset">Cancel</button></Link> */}
                  <Link to="/videoindex" className="btn btn-secondary" >Back</Link>
            </footer>
          </div>
    );
  }
}

export default VideoView;
