import React, {Component} from "react";
import API from "../config/api";
import { Navigate } from "react-router-dom";

class Login extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       name:'+85512345678',
       password: '123456',
       user: null, 
       error: null
    }
  }

  async getAllUsers() {
    try {
      let results = await API.getAllUsers("");

    console.log(results);
    } catch (error) {
      console.log(error)
    }
  }

  componentWillMount() {
    this.getAllUsers()
  }

  handelInput = (event) => {
    event.preventDefault();
    let value = event.target.value;
    let name = event.target.name;
    
    this.setState({[name] : value})
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let results = await API.login({
        "phone_number": this.state.name,
        "code": this.state.password
      });
      
      if (results.result === 1){
        this.setState({ user: results.result })
        localStorage.setItem('login',JSON.stringify(results))
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
        <div className="row no-gutters min-h-fullscreen bg-white">
      <div className="col-md-6 col-lg-7 col-xl-8 d-none d-md-block bg-img" style={{ backgroundImage: `url(${require("../assets/assets_theme/img/gallery/11.jpg")})` }} data-overlay="5">

        <div className="row h-100 pl-50">
          <div className="col-md-10 col-lg-8 align-self-end">
            <img src={require('../assets/assets_theme/img/logo-light-lg.png')} alt="..." />
            <br /><br /><br />
            <h4 className="text-white">The admin is the best admin framework available online.</h4>
            <p className="text-white">Credibly transition sticky users after backward-compatible web services. Compellingly strategize team building interfaces.</p>
            <br /><br />
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-5 col-xl-4 align-self-center">
        <div className="px-80 py-30">
          <h4>Login</h4>
          <p><small>Sign into your account</small></p>
          <br />

          {this.state.error && <p>{this.state.error.message}</p>}
          {this.state.user && (
            <Navigate to="/" replace={true} />
          )}

          <form className="form-type-material" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input type="text" class="form-control" id="username" name="name" value={this.state.name} onChange={this.handelInput} />
              <label for="username">Username</label>
            </div>

            <div className="form-group">
              <input type="password" class="form-control" name="password" id="password" value={this.state.password} onChange={this.handelInput} />
              <label for="password">Password</label>
            </div>

            <div className="form-group flexbox">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" checked />
                <label className="custom-control-label">Remember me</label>
              </div>

              <a class="text-muted hover-primary fs-13" href="/">Forgot password?</a>
            </div>

            <div className="form-group">
              <button className="btn btn-bold btn-block btn-primary" type="submit">Login</button>
            </div>
          </form>

          <div className="divider">Or Sign In With</div>
          <div className="text-center">
            <a className="btn btn-square btn-facebook" href="/"><i class="fa fa-facebook"></i></a>
            <a className="btn btn-square btn-google" href="/"><i class="fa fa-google"></i></a>
            <a className="btn btn-square btn-twitter" href="/"><i class="fa fa-twitter"></i></a>
          </div>

          <hr className="w-30px" />

          <p className="text-center text-muted fs-13 mt-20">Don't have an account? <a class="text-primary fw-500" href="/">Sign up</a></p>
        </div>
      </div>
    </div>
    );
  }
}

export default Login;
