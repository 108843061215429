import React, {Component} from "react";

import CustomModal from "../Bar/CustomModal";
import Pagination from "../Bar/Pagination";
import API from "../../config/api";
import { Link } from "react-router-dom";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class UserIndex extends Component {

  state = {
    page: 1,
    data: null,
    users: [],
    total: 0
  }
  // data = [
  //   { name: "John", email: "john@example.com", age: 28 },
  //   { name: "Jane", email: "jane@example.com", age: 32 }
  // ];

  UNSAFE_componentWillMount() {
    this.getAllUsers();
  }

  exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(this.state.users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "data.xlsx");
};

  getAllUsers = async () => {
    try {
      let query = `page=${this.state.page}`
      let results = await API.getAllUsers(query);

      let data = results.data.result;
      console.log(data)
      
      this.setState({ users : data.users, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onSearch = async (event) => {
    event.preventDefault();
    let value = event.target.value;
    try {
      let query = `page=${this.state.page} + &&s=${value}`
      let results = await API.getAllUsers(query);
      
      let data = results.data.result;
      
      this.setState({ users : data.users, total: data.total });

    } catch (error) {
      console.log(error)
    }
  }

  onPaginationPress = (page) => {
    this.state.page = page;
    // this.setState({ page : page})
    this.getAllUsers();
    this.setState({})
  }

  delete = (id) => {
    console.log(id)
  }

  sort = () => {
    alert('In construction')
  }

  render() {
    return (
        <div className="col-12 ">
          <div className="card card-body card-hover-shadow p-40">
            <h4 className="card-title"><strong>Total user are: <b> {this.state.total}</b></strong></h4>

            <div className="card-body">

            <div className="flexbox mb-20">
                <div className="lookup">
                  <input className="w-200px" type="text" name="s" placeholder="Search" onChange={this.onSearch} />
                </div>

                <div className="btn-toolbar">
                  <div className="btn-group btn-group-sm">
                    <button className="btn btn-primary dropdown-toggle" data-toggle="dropdown">Sort</button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link className="dropdown-item" onClick={this.sort}><i class="fa fa-user"></i>Free user</Link>
                      <Link className="dropdown-item" onClick={this.sort}><i class="fa fa-solid fa-bell"></i>Subscription</Link>
                    </div>
                    {/* <Link to="/usernew" > <button className="btn" title="Add new" data-provide="tooltip"> <i className="ion-plus-round" ></i></button></Link> */}
                  </div>

                  <div className="btn-group btn-group-sm ml-2 d-none d-sm-flex">
                    <button className="btn btn-info dropdown-toggle" data-toggle="dropdown">Export</button>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link class="dropdown-item" onClick={this.sort} ><i class="fa fa-file-zip-o"></i> Zip</Link>
                        <Link class="dropdown-item" onClick={this.sort} ><i class="fa fa-file-pdf-o"></i> PDF</Link>
                        <Link class="dropdown-item" onClick={this.exportToExcel} ><i class="fa fa-file-excel-o"></i> Excel</Link>
                    </div>
                  </div>
                </div>
              </div>
            <div>
          </div>

              <table className="table table-separated">
                <thead>
                  <tr>
                    <th className="text-center w-20px"><h6>#</h6></th>
                    <th className="text-center w-40px"><h6>Profile</h6></th>
                    <th className="text-center w-180px"><h6>User Name</h6></th>
                    <th className="text-center w-200px"><h6>Phone Number</h6></th>
                    <th className="text-center w-180px"><h6>Gender</h6></th>
                    <th className="text-center w-200px"><h6>Email</h6></th>
                    <th className="text-center w-180px"><h6>Join Date</h6></th>
                    <th className="text-center  "><h6>Actions</h6></th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.users.map((user, index) => {
                    return <tr>
                      <th className="text-center w-20px" scope="row"><b>{( (this.state.page - 1) * 10) + (index+ 1)}</b></th>
                      {/* <td><img style={{width: 30, height: 30, borderRadius: 30}} src={user.profile_url} alt="user profile" /></td> */}
                      <td className="text-center w-40px">
                          <div class="media">
                            <img class="avatar" src={user.profile_url ? user.profile_url : require('../../assets/assets_theme/img/avatar/1.jpg')} alt="..." />
                            <div class="media-body">
                              {/* <p >{video.title_kh}</p> */}
                            </div>
                          </div>
                        </td>
                      <td className="text-center w-180px"><strong>{user.nick_name}</strong></td>
                      <td className="text-center w-200px">{user.phone_number}</td>
                      <td className="text-center w-180px">{user.gender_id === 1? "Male" : "Female"}</td>
                      <td className="text-center w-200px">{user.email}</td>
                      <td className="text-center w-180px">{user.created_at}</td>
                      {/* <td className="text-right table-actions"></td> */}
                      <td className="text-center table-actions text-center ">
                        <Link title="View" class="btn btn-square btn-round btn-primary" to={`/userview?id=${user.id}`}><i className="fa fa-eye"></i></Link>
                        <span>&nbsp;</span><Link title="Edit" class="btn btn-square btn-round btn-info" to={`/useredit?id=${user.id}`}><i className="ti-pencil"></i></Link>
                        <span>&nbsp;</span><Link title="Block" class="btn btn-square btn-round btn-danger"><i className="fa fa-minus-square" data-toggle="modal" data-target="#modal-center" onClick={() => this.setState({data: user})}></i></Link>

                        {/* btn-outline */}
                        {/* <Link className="table-action hover-primary" title="View" to={`/userview?id=${user.id}`}><i className="fa fa-eye"></i></Link>
                        <Link className="table-action hover-primary" title="Edit" to={`/useredit?id=${user.id}`}><i class="ti-pencil"></i></Link>
                        <span>&nbsp;</span><i className="fa fa-minus-square table-action hover-danger" data-toggle="modal" data-target="#modal-center" title="Block" onClick={() => this.setState({data: user})}></i> */}



                        {/* <i className="ti-trash table-action hover-danger" data-toggle="modal" data-target="#modal-center" title="Delete" onClick={() => this.setState({data: user})}></i>  */}


                        {/* <div className="dropdown table-action">
                          <span className="dropdown-toggle no-caret hover-primary" data-toggle="dropdown" title="More"><i class="ti-more-alt rotate-90"></i></span>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" href="/"><i class="ti-menu-alt"></i> Details</a>
                            <a className="dropdown-item" href="/"><i class="ti-clip"></i> Add file</a>
                            <a className="dropdown-item" href="/"><i class="ti-bar-chart"></i> Performance</a>
                          </div>
                        </div> */}
                      </td>
                    </tr>
                  })
                }
                </tbody>
              </table>
              <Pagination 
                onPaginationPress={this.onPaginationPress}
                totalPageLength = {this.state.total}
                selectPage = {this.state.page}
              />
            </div>
          </div>
          {
              this.state.data &&
              <CustomModal 
                data={ this.state.data }  
                title="Do you really want to block this user?"
                delete = {this.delete}
              />
           }
        </div>
    );
  }
}

export default UserIndex;

